import React, { useCallback, useState } from 'react'
import Save from './Utilities/Icons/Save'
import LoadingSpinner from './Utilities/LoadingSpinner'
import useAutomations from '../hooks/useAutomations'
import AutomationManager from './AutomationManager'
import { Automation } from '../models/types'
import NewAutomationModal from './NewAutomationModal'

function App() {
  const {
    automations,
    automationsLoading,
    postAutomation,
    putAutomation,
    deleteAutomation
  } = useAutomations()

  const onSaved = useCallback(() => {
    //show animated save icon for 5.8 sec
    setTimeout(() => {
      setSaveIconEnabled(false)
    }, 5800)
    setSaveIconEnabled(true)
  }, [])

  const [saveIconEnabled, setSaveIconEnabled] = useState(false)

  const saveChanges = useCallback(
    async (automation: Automation) => {
      if (!automation) {
        console.error('trying to save null Automation')
        return
      }

      console.log('Saving...', automation)
      await putAutomation(automation)
      onSaved()
    },
    [onSaved, putAutomation]
  )

  const serverNotStarted = !automationsLoading && !automations
  const noAutomations =
    !automationsLoading && automations && automations.length === 0
  const hasAutomations =
    !automationsLoading && automations && automations.length > 0

  return (
    <div className="app">
      <div className="fixed top-2 right-5 z-50">
        <div className="text-center">
          <h2 className="text-base font-semibold tracking-wide text-gray-50 uppercase">
            <div>Velaro Automation</div>
          </h2>
        </div>
      </div>
      {saveIconEnabled && (
        <div className="fixed top-6 left-10 z-50 ping">
          <Save /> Saved
        </div>
      )}
      {automationsLoading && (
        <div className="fixed top-1/2 left-1/2 -translate-x-1/2">
          <LoadingSpinner />
        </div>
      )}
      {serverNotStarted && (
        <div className="fixed top-1/2 left-1/2 -translate-x-1/2">
          Start your damn server!
        </div>
      )}
      {noAutomations && (
        <NewAutomationModal isFirst={true} onConfirm={postAutomation} />
      )}
      {hasAutomations && (
        <AutomationManager
          allAutomations={automations}
          saveChanges={saveChanges}
          onNewAutomation={postAutomation}
          onDeleteAutomation={deleteAutomation}
        />
      )}
    </div>
  )
}

export default App
