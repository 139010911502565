import * as React from 'react'
import LoginCallback from '../components/Auth/LoginCallback'
import LoginCallbackError from '../components/Auth/LoginCallbackError'
import Logout from '../components/Auth/Logout'
import LogoutCallback from '../components/Auth/LogoutCallback'
import { Route, Routes } from 'react-router-dom'
import LoginUnauthorized from '../components/Auth/LoginUnauthorized'
import Main from '../Main'
import usePrivateRoute from './PrivateRoute'

export function AppRoutes() {
  const { renderPrivateRoute } = usePrivateRoute()
  return (
    <Routes>
      <Route path="/login/callback" Component={LoginCallback} />
      <Route path="/login/callback/error" Component={LoginCallbackError} />
      <Route path="/login/unauthorized" Component={LoginUnauthorized} />
      <Route path="/logout" Component={Logout} />
      <Route path="/logout/callback" Component={LogoutCallback} />
      <Route path="/" Component={() => renderPrivateRoute(Main)} />
    </Routes>
  )
}
