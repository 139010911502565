import React from 'react'

interface Props {
  text: string
  onClick?(): void
}

export default function QuickReply({ text, onClick }: Props) {
  return (
    <button
      className="py-2 px-8 max-w-full max-h-24 text-white truncate bg-indigo-500 hover:bg-indigo-800 rounded-full"
      type="button"
      onClick={onClick}
    >
      {text}
    </button>
  )
}
