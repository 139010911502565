import React, { ReactNode } from "react";
import Avatar from "../Avatar";
import LoadingSpinner from "../Utilities/LoadingSpinner";
import useMarked from "../../hooks/useMarked";

interface Props {
  message: string | ReactNode;
}

export default function TestBotMessage({ message }: Props) {
  const { content } = useMarked(message);

  if (content === undefined) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex justify-start">
      <Avatar
        src={
          "https://cdn1.iconfinder.com/data/icons/robots-avatars-set/354/Cute_robot___robot_robo_cute_cyborg-512.png"
        }
      />
      <>
        {typeof content === "string" && (
          <div
            className="overflow-hidden p-3 mb-4 ml-2 leading-7 overflow-ellipsis bg-pink-100 rounded-2xl"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        )}
        {typeof content !== "string" && (
          <div className="overflow-hidden p-3 mb-4 ml-2 leading-7 overflow-ellipsis bg-pink-100 rounded-2xl">
            {content}
          </div>
        )}
      </>
    </div>
  );
}
