import React from 'react'

interface Props {
  text: string
  textSize?: string
  onClick(): void
}

export default function Button({ text, textSize = 'text-sm', onClick }: Props) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`px-4 py-1 m-1 text-gray-500 hover:text-blue-500 active:text-blue-800 rounded-lg border shadow-sm active:shadow-inner ${textSize}`}
    >
      {text}
    </button>
  )
}
