import React, { useCallback, useEffect, useRef, useState } from "react";
import useLeftClick from "../../hooks/useLeftClick";
import useMouseDown from "../../hooks/useMouseDown";
import useRightClick from "../../hooks/useRightClick";
import { sleep } from "../../utils";
import QuickReply from "../Utilities/QuickReply";
import Clickable from "../Utilities/Clickable";
import Ellipsis from "../Utilities/Icons/Ellipsis";
import Play from "../Utilities/Icons/Play";
import TextArea from "../Utilities/TextArea";
import Tooltip from "../Utilities/Tooltip";

import TestBotMessage from "./TestBotMessage";
import TestUserMessage from "./TestUserMessage";
import { continueJob, startJob } from "../../services/JobService";

import TestSystemMessage from "./TestSystemMessage";
import {
  AutomationStatus,
  DataField,
  TestChatMessage,
} from "../../models/types";
import { JobOutput, ContinueJob } from "../../models/api";

interface Props {
  automationName: string;
}

export default function TestWindow({ automationName }: Props) {
  const messageListRef = useRef<HTMLDivElement>(null);
  const [el, setEl] = useState<HTMLDivElement | null>(null);
  const [testChatLog, setTestChatLog] = useState<TestChatMessage[]>([]);
  const [userText, setUserText] = useState("");
  const [showTypingIndicator, setShowTypingIndicator] = useState(false);
  const [botTyping, setBotTyping] = useState(false);
  // const [data, setData] = useState<Record<string, string>>({})
  const [quickReplies, setQuickReplies] = useState<string[]>([]);
  const [jobId, setJobId] = useState<string | null>();
  const [status, setStatus] = useState<string | null>(null);
  const [requestedData, setRequestedData] = useState<DataField | null>(null);

  useLeftClick(el, () => null);
  useRightClick(el, () => null);
  useMouseDown(el, () => null);

  const addBotMessages = useCallback(async (messages: string[]) => {
    if (!messages || !messages.length) {
      return;
    }
    setBotTyping(true);
    await sleep(1000);
    for await (const message of messages) {
      if (!message) {
        continue;
      }

      setShowTypingIndicator(true);
      await sleep(1500);
      setTestChatLog((prev) => [...prev, { text: message, author: "bot" }]);
      setShowTypingIndicator(false);
      await sleep(500);
    }
    await sleep(500);
    setBotTyping(false);
  }, []);

  const handleJobResponse = useCallback(
    (response: JobOutput) => {
      if (response.error) {
        addBotMessages(["I encountered an error: ", response.error]);
        return;
      }

      setStatus(response.status);
      setJobId(response.jobId);
      addBotMessages(response.messages || []);
      setQuickReplies(response.quickReplies || []);
      setRequestedData(
        response.requestedData ? response.requestedData[0] : null
      );
    },
    [addBotMessages]
  );

  const start = useCallback(async () => {
    setTestChatLog([]);
    setUserText("");
    const startJobResponse = await startJob({ automationName });
    handleJobResponse(startJobResponse);
  }, [automationName, handleJobResponse]);

  useEffect(() => {
    if (!status) {
      //status wull only be null once at the beginning
      console.log("Starting chat automatically");
      start();
    }
  }, [start, status]);

  async function submitUtterance(utterance: string) {
    setUserText("");

    if (!jobId) {
      return;
    }

    setTestChatLog((prev) => [...prev, { text: utterance, author: "user" }]);
    setQuickReplies([]);

    const payload: ContinueJob = { jobId };

    if (requestedData) {
      payload.data = {};
      payload.data[requestedData.name] = utterance;
    } else {
      payload.utterance = utterance;
    }

    const continueJobResponse = await continueJob(payload);
    handleJobResponse(continueJobResponse);
  }

  function renderMessage(message: TestChatMessage, i: number) {
    const props = { key: i, message: message.text };
    switch (message.author) {
      case "bot":
        return <TestBotMessage {...props} />;

      case "user":
        return <TestUserMessage {...props} />;
    }
  }

  function scrollToBottom() {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }

  scrollToBottom();

  const disableTextBox = !status || status === AutomationStatus.Done;

  return (
    <div
      ref={(el) => setEl(el)}
      style={{ width: "400px", minHeight: "500px" }}
      className="fixed inset-y-28 left-10 z-40 p-3 w-16 bg-gradient-to-r from-gray-100 to-gray-50 shadow-lg cursor-default"
    >
      <div className="flex flex-col justify-between w-full h-full">
        <div className="mb-5 w-full text-lg text-center border-b">
          Test
          <div className="float-right">
            <Tooltip text="Restart">
              <Clickable onClick={start}>
                <Play />
              </Clickable>
            </Tooltip>
          </div>
        </div>
        <div
          ref={messageListRef}
          className="flex overflow-y-auto flex-col p-1 h-full message-list"
        >
          {testChatLog.map(renderMessage)}
          {showTypingIndicator && <TestBotMessage message={<Ellipsis />} />}
          {!botTyping && quickReplies.length > 0 && (
            <div className="pb-2">
              {quickReplies.map((label, i) => (
                <div key={i} className="p-2">
                  <QuickReply
                    text={label}
                    onClick={() => submitUtterance(label)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        {status === AutomationStatus.Done && !botTyping && (
          <TestSystemMessage>
            {"The automation has finished."}
          </TestSystemMessage>
        )}

        {status === AutomationStatus.TimedWait && (
          <TestSystemMessage>{"The automation is waiting."}</TestSystemMessage>
        )}

        <TextArea
          disabled={disableTextBox}
          placeholder={"Type a message"}
          value={userText}
          onChange={setUserText}
          onEnter={() => submitUtterance(userText)}
        />
      </div>
    </div>
  );
}
