import { useEffect } from 'react'
import { Pos } from '../models/types'

export default function useLeftClick(
  el: HTMLDivElement | null,
  onClick: (pos: Pos) => void
) {
  useEffect(() => {
    if (!el) {
      return
    }

    const onLeftClick = function (e: MouseEvent) {
      onClick({ x: e.offsetX, y: e.offsetY })
    }

    el.addEventListener('click', onLeftClick)
    return () => {
      el.removeEventListener('click', onLeftClick)
    }
  }, [onClick, el])
}
