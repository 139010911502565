import { NodeBase } from '../models/nodes'
import { Pos, Missing } from '../models/types'

export function getNodeIndexAtPos(nodes: NodeBase[], pos: Pos | Missing) {
  if (!nodes || !pos) {
    return -1
  }
  return nodes.findIndex((node) => posMatch(node.pos, pos))
}

export function posMatch(pos1: Pos | Missing, pos2: Pos | Missing) {
  if (!pos1 || !pos2) {
    return false
  }
  return pos1.x === pos2.x && pos1.y === pos2.y
}

export function getNodeIdFromEventPath(path: HTMLElement[]) {
  const nodeEl = path.find(
    (el) => el.classList && el.classList.contains('node')
  )
  return nodeEl?.id || null
}
