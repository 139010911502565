import React from 'react'
import { WaitNode } from '../../models/nodes'
import { WaitMode } from '../../models/types'
import Border from '../Utilities/Border'
import FormGroup from '../Utilities/FormGroup'
import RelativeTimePicker from '../Utilities/RelativeTimePicker'
import TextBox from '../Utilities/TextBox'
import ToggleButtonGroup from '../Utilities/ToggleButtonGroup'

interface Props {
  node: WaitNode
  update(node: WaitNode): void
}

const options = [
  { label: 'wait for', value: 'wait_for' },
  { label: 'wait until', value: 'wait_until' }
]

export default function WaitNodeEditor({ node, update }: Props) {
  return (
    <>
      <FormGroup>
        <TextBox
          placeholder="node name"
          onChange={(value) => update({ ...node, name: value })}
          value={node.name || ''}
        />
      </FormGroup>
      <Border />
      <FormGroup>
        <ToggleButtonGroup
          value={node.waitType}
          options={options}
          onChange={(value: WaitMode) => update({ ...node, waitType: value })}
        />
      </FormGroup>
      {node.waitType === 'wait_for' && (
        <FormGroup>
          <RelativeTimePicker
            relativeTime={node.waitForValue}
            onChange={(val) => {
              update({ ...node, waitForValue: val })
            }}
          />
        </FormGroup>
      )}

      {node.waitType === 'wait_until' && (
        <FormGroup>
          <span>Wait until - wip</span>
        </FormGroup>
      )}
    </>
  )
}
