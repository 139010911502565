import React from 'react'

interface Props {
  value: string
  onChange(value: string): void
  placeholder?: string
  width?: string
  maxlength?: number
  notValid?: boolean
}

export default function TextBox({
  value,
  onChange,
  placeholder,
  width,
  maxlength,
  notValid
}: Props) {
  width = width || 'w-full'
  return (
    <input
      placeholder={placeholder}
      className={`inline p-2 ${width} text-sm rounded-lg border shadow-sm ${
        notValid && 'border-red-500'
      }`}
      onChange={(e) => onChange(e.currentTarget.value)}
      value={value}
      maxLength={maxlength}
    />
  )
}
