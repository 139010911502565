import { useCallback, useEffect, useState } from 'react'
import { add, getAll, remove, update } from '../services/AutomationService'
import mapNodesFromServer from '../helpers/mapNodesFromServer'
import { Automation } from '../models/types'
import { Template } from '../models/templates'

export default function useAutomations() {
  const [automationsLoading, setAutomationsLoading] = useState(true)
  const [automations, setAutomations] = useState<Automation[] | null>(null)

  const fetchAutomations = useCallback(async () => {
    try {
      const all = await getAll()
      //we have to go through all nodes on all bots and convert them to the front-end classes for polymorphism
      //todo: look into something better
      all.map(mapNodesFromServer)
      setAutomations(all)
    } catch (error) {
      console.error('Failed to fetch automations', error)
    } finally {
      setAutomationsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchAutomations()
  }, [fetchAutomations])

  const postAutomation = useCallback(
    async (template: Template, name: string) => {
      try {
        setAutomationsLoading(true)
        const automation = await add({ template, name })
        mapNodesFromServer(automation)
        console.log('saved new Automation!', automation)
        setAutomations((bd) => [automation, ...(bd || [])])
      } catch (error) {
        console.error('Failed to add automation', error)
      } finally {
        setAutomationsLoading(false)
      }
    },
    []
  )

  const putAutomation = useCallback(async (Automation: Automation) => {
    try {
      await update(Automation)
      setAutomations((bd) => {
        const clone = [...(bd || [])]
        clone[clone.findIndex((x) => x.id === Automation.id)] = Automation
        return clone
      })
    } catch (error) {
      console.error('Failed to update automation', error)
    }
  }, [])

  const deleteAutomation = useCallback(async (id: number) => {
    try {
      setAutomationsLoading(true)
      await remove({ id })
      setAutomations((bd) => {
        const clone = [...(bd || [])]
        const index = clone.findIndex((x) => x.id === id)
        clone.splice(index, 1)
        return clone
      })
    } catch (error) {
      console.error('Failed to delete automation', error)
    } finally {
      setAutomationsLoading(false)
    }
  }, [])

  return {
    automations,
    automationsLoading,
    postAutomation,
    putAutomation,
    deleteAutomation
  }
}
