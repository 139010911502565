import React, { useMemo } from 'react'
import { UserNode, NodeOptions } from '../../../models/nodes'
import RoundedNode from './shared/RoundedNode'

interface Props {
  node: UserNode
  options: NodeOptions
}

export default function CanvasUserNode({ node, options }: Props) {
  const utterance = useMemo(() => {
    return node.utterance || '(Empty)'
  }, [node.utterance])
  return (
    <RoundedNode
      highlightColor={options.highlightColor}
      bg="bg-indigo-50"
      border="border-indigo-500"
    >
      <span>{utterance}</span>
    </RoundedNode>
  )
}
