import { NodeBase, NodeType } from "./nodes";

export type Missing = null | undefined;

export interface Pos {
  x: number;
  y: number;
}

export interface Automation {
  id: number;
  name: string;
  config: AutomationConfig;
}

export interface DataSet {
  name: string;
  data: Record<string | number, string>;
}

export interface NodeArrowData {
  left: number;
  top: number;
  width: number;
  height: number;
  pos: Pos;
  offset: number;
}

export interface AutomationConfig {
  nodes: NodeBase[];
}

export interface AutomationUpdate {
  type: string;
  data: any;
}

export interface ContextMenuData {
  type: "canvas" | "node";
  pos: Pos;
  nodeIndex?: number;
  allowRemoveNode?: boolean;
}

export interface SelectionModeData {
  originNodeIndex?: number;
  newNodeType?: NodeType;
  selectionType: "add-node" | "modify-links" | "select";
}

export interface DragNodeData {
  init?: boolean; //drag is being initialized
  nodeIndex: number;
  currentPos: Pos | null;
}

export interface TestChatMessage {
  text: string;
  author: "bot" | "user";
}

export interface DataField {
  name: string;
  type: DataFieldType;
}

export interface Condition {
  priority: number;
  data: string;
  comparator: Comparator;
  value: string;
}

export const comparators = [
  "<",
  ">",
  "<=",
  ">=",
  "=",
  "!=",
  "contains",
  "missing",
  "exists",
  "valid",
  "invalid",
] as const;
export type Comparator = typeof comparators[number];

export interface CustomActionData {
  url: string;
  method: string;
  data: string[];
}

export enum AutomationStatus {
  Done = "done",
  UserWait = "user_wait",
  DataWait = "data_wait",
  TimedWait = "timed_wait",
}

export const DataFieldTypeDict: Record<string, string> = {
  string: "text",
  email: "email",
  number: "number",
  phone: "phone",
  set: "set",
};
export type DataFieldType = keyof typeof DataFieldTypeDict;

export type DataMode = "request" | "set";
export type WaitMode = "wait_for" | "wait_until";
export type GoToMode = "jump" | "land";

export const StopActionDefs: Record<string, string> = {
  "end-automation": "End automation",
  "route-to-team": "Route to team",
  "route-to-agent": "Route to agent",
};

export type StopActionType = keyof typeof StopActionDefs;
