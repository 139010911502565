import React, { useMemo } from 'react'
import NumberBox from './NumberBox'
import Select from './Select'

const timeUnits = ['seconds', 'minutes', 'hours', 'days'] as const
type TimeUnit = typeof timeUnits[number]

export type RelativeTime = {
  value: number
  units: TimeUnit
}

interface Props {
  relativeTime: RelativeTime
  onChange(relativeTime: RelativeTime): void
}

export default function RelativeTimePicker({ relativeTime, onChange }: Props) {
  const timeUnitOpions = useMemo(
    () =>
      timeUnits.map((t) => {
        return {
          key: t,
          value: relativeTime.value === 1 ? t.slice(0, t.length - 1) : t //trim pluralization if val == 1
        }
      }),
    [relativeTime.value]
  )

  return (
    <div className="flex gap-x-4">
      <NumberBox
        value={relativeTime.value}
        onChange={(val) => onChange({ ...relativeTime, value: val })}
        min={1}
        max={999}
        step={0.1}
      />
      <Select
        options={timeUnitOpions}
        value={relativeTime.units}
        onChange={(val: TimeUnit) => onChange({ ...relativeTime, units: val })}
      />
    </div>
  )
}
