import { useEffect } from 'react'

export default function useHover(
  el: HTMLDivElement | null,
  onHoverChange: (hovering: boolean) => void
) {
  useEffect(() => {
    if (!el) {
      return
    }

    const onBeginHovering = function () {
      onHoverChange(true)
    }

    const onEndHovering = function () {
      onHoverChange(false)
    }

    el.addEventListener('mouseover', onBeginHovering)
    el.addEventListener('mouseleave', onEndHovering)
    return () => {
      el.removeEventListener('mouseover', onBeginHovering)
      el.removeEventListener('mouseleave', onEndHovering)
    }
  }, [el, onHoverChange])
}
