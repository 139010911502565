import React, { memo, useCallback, useState } from 'react'
import useDroppable from '../../hooks/useDroppable'
import useLeftClick from '../../hooks/useLeftClick'
import useHover from '../../hooks/useHover'

interface Props {
  children: JSX.Element
  equalityObj: unknown
  className?: string
  x: number
  y: number
  onDragEnter(): void
  onDragLeave(): void
  onDrop(): void
  onLeftClick(): void
  onHoverChange(bool: boolean): void
  onLoad(el: HTMLDivElement): void
}

const CanvasGridItem = memo(
  function CanvasGridItem({
    children,
    className,
    x,
    y,
    onDragEnter,
    onDragLeave,
    onDrop,
    onLeftClick,
    onHoverChange,
    onLoad
  }: Props) {
    const [el, setEl] = useState(null)

    const onRender = useCallback(
      (el: any) => {
        if (!el) {
          return
        }

        setEl(el)
        onLoad(el)
        return
      },
      [onLoad]
    )

    useHover(el, onHoverChange)
    useDroppable(el, onDragEnter, onDragLeave, onDrop)
    useLeftClick(el, onLeftClick)

    return (
      <div
        ref={onRender}
        data-x={x}
        data-y={y}
        style={{
          gridColumn: x.toString(),
          gridRow: y.toString()
        }}
        className={`grid-item ${className || ''}`}
      >
        {children}
      </div>
    )
  },
  (prev, next) => {
    return JSON.stringify(prev.equalityObj) === JSON.stringify(next.equalityObj)
  }
)

export default CanvasGridItem
