import React from 'react'

interface Props {
  color?: string
  children: JSX.Element | string | undefined
}

//these styles need to be hard-coded because interpolating breaks tailwind's JIT compiler
const colors: Record<string, string> = {
  blue: `bg-blue-100`,
  green: `bg-green-100`,
  yellow: `bg-yellow-100`,
  orange: `bg-orange-100`,
  red: `bg-red-100`
}

export function Tag({ children, color = 'blue' }: Props) {
  const colorStyle: string = colors[color] || colors['blue']
  return (
    <span className={`p-1 text-gray-900 rounded ${colorStyle}`}>
      {children}
    </span>
  )
}
