import React from 'react'
import { GoToNode, NodeOptions } from '../../../models/nodes'
import GoTo from '../../Utilities/Icons/GoTo'
import RectangleNode from './shared/RectangleNode'

interface Props {
  node: GoToNode
  options: NodeOptions
}

export default function CanvasGoToNode({ node, options }: Props) {
  const iconStyle =
    node.goToMode === 'land' ? { transform: 'scale(-1, 1)' } : {}

  return (
    <RectangleNode
      bg={'bg-orange-50'}
      border={'border-orange-800'}
      icon={<GoTo color={'text-orange-800'} />}
      highlightColor={options.highlightColor}
    >
      <div style={iconStyle} className="absolute left-2 opacity-70"></div>
      <div className={`flex flex-wrap tracking-wide px-2 py-4`}>
        {node.goToMode === 'jump' && (
          <span className="pr-1 text-lg">Jump to</span>
        )}
        <span className="text-lg font-bold">{node.target}</span>
      </div>
    </RectangleNode>
  )
}
