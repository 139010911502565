import { useEffect } from 'react'

export default function useRightClick(
  el: HTMLElement | null,
  onClick: (e: MouseEvent) => void
) {
  useEffect(() => {
    if (!el) {
      return
    }
    const onRightClick = function (e: MouseEvent) {
      e.stopPropagation()
      onClick(e)
    }

    el.addEventListener('contextmenu', onRightClick)
    return () => {
      el.removeEventListener('contextmenu', onRightClick)
    }
  }, [el, onClick])
}
