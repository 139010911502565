import React, { ReactElement } from 'react'

interface Props {
  children: ReactElement | ReactElement[] | boolean
}
export default function FormGroupInline({ children }: Props) {
  return (
    <>
      <div className="flex flex-row justify-around items-center mb-4 form-group-inline">
        {children}
      </div>
    </>
  )
}
