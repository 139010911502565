import React from 'react'
import Avatar from '../Avatar'

interface Props {
  message: string
}

export default function TestUserMessage({ message }: Props) {
  return (
    <div className="flex justify-end">
      <div className="overflow-hidden p-3 mr-2 mb-4 leading-7 overflow-ellipsis bg-indigo-100 rounded-2xl">
        {message}
      </div>
      <Avatar />
    </div>
  )
}
