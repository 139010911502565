import React, { useState } from 'react'
import useSelectedAutomation from '../hooks/useSelectedAutomation'
import { Automation } from '../models/types'
import Canvas from './Canvas/Canvas'
import AutomationSelectToolbar from './Toolbar/AutomationSelectToolbar'
import ConfirmModal from './Utilities/ConfirmModal'
import NewAutomationModal from './NewAutomationModal'
import { Template } from '../models/templates'

interface Props {
  allAutomations: Automation[]
  saveChanges(updatedAutomation: Automation): void
  onNewAutomation(template: Template, name: string): void
  onDeleteAutomation(id: number): void
}

function AutomationManager({
  allAutomations,
  saveChanges,
  onNewAutomation,
  onDeleteAutomation
}: Props) {
  const { selectedIndex, selectedAutomation, changeAutomation } =
    useSelectedAutomation(allAutomations)
  const [showNewAutomationModal, setShowNewAutomationModal] = useState(false)
  const [showDeleteAutomationModal, setShowDeleteAutomationModal] =
    useState(false)

  return (
    <>
      {selectedAutomation && (
        <>
          <Canvas
            selectedAutomation={selectedAutomation}
            onSave={saveChanges}
          />
          <AutomationSelectToolbar
            allAutomations={allAutomations}
            selectedIndex={selectedIndex}
            onSelectAutomation={changeAutomation}
            onNewAutomation={() => setShowNewAutomationModal(true)}
            onDeleteAutomation={() => setShowDeleteAutomationModal(true)}
          />
        </>
      )}

      {showNewAutomationModal && (
        <NewAutomationModal
          isFirst={false}
          onConfirm={onNewAutomation}
          onCancel={() => setShowNewAutomationModal(false)}
        />
      )}

      {selectedAutomation && showDeleteAutomationModal && (
        <ConfirmModal
          text={'Are you sure you want to delete this automation?'}
          onConfirm={() => {
            setShowDeleteAutomationModal(false)
            onDeleteAutomation(selectedAutomation.id)
          }}
          onReject={() => setShowDeleteAutomationModal(false)}
        />
      )}
    </>
  )
}

export default AutomationManager
