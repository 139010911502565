import React from 'react'

interface Props {
  children: JSX.Element
  text: string
}

export default function Tooltip({ children, text }: Props) {
  return (
    <div className="relative tooltip">
      <span className="absolute py-1 text-base text-center text-gray-500 bg-gradient-to-r from-gray-100 to-gray-50 rounded-md shadow-sm scale-75 tooltip-text">
        {text}
      </span>
      {children}
    </div>
  )
}
