import React, { ReactElement } from 'react'

interface Props {
  highlightColor?: string
  border?: string
  bg?: string
  children: ReactElement | ReactElement[]
}

export default function RoundedNode({
  highlightColor,
  children,
  bg,
  border
}: Props) {
  const className = `rounded-full ${bg || 'bg-gray-100'} ${
    highlightColor
      ? `ring-4 ${highlightColor}`
      : `border ${border} shadow-md shadow-gray-700`
  }`

  return (
    <div className={className}>
      <div
        className={`py-2 px-8 max-w-full text-lg font-bold max-h-24  truncate text-gray-900`}
      >
        {children}
      </div>
    </div>
  )
}
