import React, { useCallback } from 'react'
import { GoToNode } from '../../models/nodes'
import { GoToMode } from '../../models/types'
import FormGroup from '../Utilities/FormGroup'
import TextBox from '../Utilities/TextBox'
import ToggleButtonGroup from '../Utilities/ToggleButtonGroup'

interface Props {
  node: GoToNode
  update(node: GoToNode): void
}

const options = [
  { label: 'jump', value: 'jump' },
  { label: 'land', value: 'land' }
]

export default function GoToNodeEditor({ node, update }: Props) {
  const updateTarget = useCallback(
    (value: string) => {
      update({ ...node, target: value })
    },
    [node, update]
  )

  return (
    <>
      <FormGroup>
        <ToggleButtonGroup
          value={node.goToMode}
          options={options}
          onChange={(value: GoToMode) => update({ ...node, goToMode: value })}
        />
      </FormGroup>
      <FormGroup>
        <TextBox
          placeholder="target"
          onChange={updateTarget}
          value={node.target}
        />
      </FormGroup>
    </>
  )
}
