import React, { useCallback } from 'react'
import { BotNode } from '../../models/nodes'
import Border from '../Utilities/Border'
import Button from '../Utilities/Button'
import FormGroup from '../Utilities/FormGroup'
import X from '../Utilities/Icons/X'
import TextEditor from '../Utilities/TextArea'
import TextBox from '../Utilities/TextBox'

interface Props {
  node: BotNode
  update(node: BotNode): void
}

export default function BotNodeEditor({ node, update }: Props) {
  const addMessage = useCallback(() => {
    update({ ...node, messages: [...node.messages, ''] })
  }, [node, update])

  const updateMessage = useCallback(
    (value: string, i: number) => {
      const clone = { ...node, messages: [...node.messages] }
      clone.messages[i] = value
      update(clone)
    },
    [node, update]
  )

  const removeMessage = useCallback(
    (i: number) => {
      const clone = { ...node, messages: [...node.messages] }
      clone.messages.splice(i, 1)
      update(clone)
    },
    [node, update]
  )

  return (
    <>
      <FormGroup>
        <TextBox
          placeholder="node name"
          onChange={(value) => update({ ...node, name: value })}
          value={node.name || ''}
        />
      </FormGroup>
      <Border />
      <FormGroup>
        <Button text="Add message" onClick={addMessage} />
      </FormGroup>
      <div className="overflow-auto">
        {node.messages.map((message, i) => (
          <FormGroup key={i}>
            <button
              type="button"
              onClick={() => removeMessage(i)}
              className="absolute -top-1 -right-1 scale-50"
            >
              <X />
            </button>
            <TextEditor
              placeholder="type a bot message"
              onChange={(value) => updateMessage(value, i)}
              value={message}
            />
          </FormGroup>
        ))}
      </div>
    </>
  )
}
