import { useEffect } from 'react'

export default function useDraggable(
  el: HTMLElement | null,
  onDragStart: (e: DragEvent) => void,
  onDragEnd: (e: DragEvent) => void
) {
  useEffect(() => {
    if (!el) {
      return
    }

    const onDragStartEvent = function (e: DragEvent) {
      onDragStart(e)
    }

    const onDragEndEvent = function (e: DragEvent) {
      onDragEnd(e)
    }

    el.addEventListener('dragstart', onDragStartEvent)
    el.addEventListener('dragend', onDragEndEvent)
    return () => {
      el.removeEventListener('dragstart', onDragStartEvent)
      el.removeEventListener('dragend', onDragEndEvent)
    }
  }, [el, onDragEnd, onDragStart])
}
