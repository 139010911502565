import React, { ReactElement } from 'react'

interface Props {
  highlightColor?: string
  border?: string
  bg?: string
  icon?: ReactElement
  children: ReactElement | ReactElement[]
}

//`ring-blue-400`
//`ring-red-400`

export default function RectangleNode({
  highlightColor,
  icon,
  children,
  bg: bgColor,
  border: borderColor
}: Props) {
  const className = `border ${borderColor} shadow-md shadow-gray-700 rounded-sm ${bgColor} ${
    highlightColor ? `ring-4 ${highlightColor}` : ''
  }`

  return (
    <div style={{ width: '250px' }} className={className}>
      <div className="flex flex-row justify-start items-center min-h-[50px] text-gray-900 ">
        <div className="px-2 opacity-70">{icon}</div>
        {children}
      </div>
    </div>
  )
}
