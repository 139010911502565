//The nodes coming from the server lose their polymorphism
//so we get it back by looking at the type property

import { NodeBase, NodeTypeDict } from '../models/nodes'
import { Automation } from '../models/types'

export default function mapNodesFromServer(automation: Automation) {
  automation.config.nodes = automation.config.nodes.map((o: NodeBase) => {
    return { ...new NodeTypeDict[o.type](), ...o }
  })
}
