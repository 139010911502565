import { NodeBase } from '../models/nodes'
import { SelectionModeData } from '../models/types'

const blueHighlight = 'ring-blue-400'
const redHighlight = 'ring-red-400'

export default function getHighlightColors(
  nodes: NodeBase[],
  selectionModeData: SelectionModeData | null,
  node: NodeBase
): { nodeHighlight?: string; arrowColor?: 'red' | 'blue' } {
  if (!selectionModeData || selectionModeData.selectionType === 'add-node') {
    return {}
  }

  const originNode = nodes[selectionModeData.originNodeIndex || 0]
  if (!originNode) {
    console.error('Origin node is null')
    return {}
  }
  const nodeIsOrigin = originNode.id === node.id
  const hasLinkToOrigin = originNode.children.includes(node.id)

  switch (selectionModeData.selectionType) {
    case 'modify-links':
      return nodeIsOrigin
        ? { arrowColor: 'red' }
        : { nodeHighlight: hasLinkToOrigin ? redHighlight : blueHighlight }
    case 'select':
      return nodeIsOrigin
        ? { nodeHighlight: blueHighlight, arrowColor: 'blue' }
        : {}
  }
}
