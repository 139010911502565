import React, { useState } from 'react'
import useLeftClick from '../../hooks/useLeftClick'
import useMouseDown from '../../hooks/useMouseDown'
import useRightClick from '../../hooks/useRightClick'

interface Props {
  children: React.ReactNode
}
export default function ForegroundPanel({ children }: Props) {
  const [el, setEl] = useState<HTMLDivElement | null>(null)
  useLeftClick(el, () => null)
  useRightClick(el, () => null)
  useMouseDown(el, () => null)

  return (
    <div
      ref={(el) => setEl(el)}
      className="p-2 w-full h-full bg-gray-100 border border-gray-700 shadow-lg cursor-default"
    >
      {children}
    </div>
  )
}
