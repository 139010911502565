import React from 'react'
import { WaitNode, NodeOptions } from '../../../models/nodes'
import Time from '../../Utilities/Icons/Time'
import RectangleNode from './shared/RectangleNode'

interface Props {
  node: WaitNode
  options: NodeOptions
}

export default function CanvasWaitNode({ node, options }: Props) {
  return (
    <RectangleNode
      bg={'bg-cyan-50'}
      border={'border-cyan-800'}
      icon={<Time color="text-cyan-800" />}
      highlightColor={options.highlightColor}
    >
      <div className={`flex flex-wrap tracking-wide px-2 py-4`}>
        <span className="text-lg">Wait</span>
        <span className="px-1 text-lg font-bold">
          {node.waitForValue.value}
        </span>
        <span className="text-lg">{node.waitForValue.units}</span>
      </div>
    </RectangleNode>
  )
}
