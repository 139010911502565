import React from 'react'
import ToggleButton from './ToggleButton'

interface Props {
  value: string
  options: { label: string; value: string }[]
  textSize?: string
  onChange(value: string): void
}

export default function ToggleButtonGroup({
  value,
  options,
  textSize,
  onChange
}: Props) {
  return (
    <div className="">
      {options.map((op, i) => (
        <ToggleButton
          key={i}
          textSize={textSize}
          text={op.label}
          toggled={value === op.value}
          onClick={() => onChange(op.value)}
        />
      ))}
    </div>
  )
}
