import React from 'react'

interface Props {
  id: string
  label: string
  checked: boolean
  onToggle(): void
}

export default function Checkbox({ id, label, checked, onToggle }: Props) {
  return (
    <div>
      <input
        className="m-2"
        id={id}
        name={id}
        type="checkbox"
        checked={checked}
        onChange={onToggle}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}
