import React, { ReactElement } from 'react'
import { Pos } from '../../models/types'

interface Props {
  pos: Pos
  children: ReactElement | ReactElement[]
}

export default function ContextMenu({ pos, children }: Props) {
  return (
    <div
      style={{ left: pos.x, top: pos.y, width: '225px' }}
      className="absolute z-30 p-0.5 bg-gradient-to-r from-gray-100 to-gray-50 rounded border border-gray-200 shadow-lg"
    >
      {children}
    </div>
  )
}
