import React, { useState } from 'react'
import Button from './Button'
import TextBox from './TextBox'

interface Props {
  text: string
  confirmBtnText?: string
  rejectBtnText?: string
  textBox?: boolean
  onConfirm(val: string | undefined): void
  onReject?(): void
}

export default function ConfirmModal({
  text,
  confirmBtnText = 'OK',
  rejectBtnText = 'Cancel',
  textBox = false,
  onConfirm,
  onReject
}: Props) {
  const [val, setVal] = useState<string | undefined>(undefined)

  return (
    <div
      style={{ width: '450px' }}
      className="flex fixed top-1/2 left-1/2 flex-col p-8 text-lg bg-gradient-to-r from-gray-100 to-gray-50 shadow-lg -translate-x-1/2 -translate-y-1/2"
    >
      {text}
      {textBox && (
        <div className="py-4">
          <TextBox value={val || ''} onChange={setVal} />
        </div>
      )}
      <div className="flex flex-row justify-between">
        {onReject && (
          <Button text={rejectBtnText} textSize="text-xl" onClick={onReject} />
        )}
        <Button
          text={confirmBtnText}
          textSize="text-xl"
          onClick={() => onConfirm(val)}
        />
      </div>
    </div>
  )
}
