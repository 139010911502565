import React from 'react'
import ForegroundPanel from '../Utilities/ForegroundPanel'

interface Props {
  items: (() => React.JSX.Element)[]
}

export default function Toolbar({ items }: Props) {
  return (
    <ForegroundPanel>
      <div className="flex flex-row justify-center items-stretch">
        {items.map((item, i) => {
          return (
            <div className="flex px-2" key={i}>
              <div className="m-auto">{item()}</div>
            </div>
          )
        })}
      </div>
    </ForegroundPanel>
  )
}
