import React, { useCallback } from 'react'
import { UserNode } from '../../models/nodes'
import Border from '../Utilities/Border'
import Checkbox from '../Utilities/Checkbox'
import FormGroup from '../Utilities/FormGroup'
import TextBox from '../Utilities/TextBox'

interface Props {
  node: UserNode
  update(node: UserNode): void
}

export default function UserNodeEditor({ node, update }: Props) {
  const updateUtterance = useCallback(
    (value: string) => {
      update({ ...node, utterance: value })
    },
    [node, update]
  )

  const toggleShowAsQuickReply = useCallback(() => {
    update({ ...node, showAsQuickReply: !node.showAsQuickReply })
  }, [node, update])

  return (
    <>
      <FormGroup>
        <TextBox
          placeholder="node name"
          onChange={(value) => update({ ...node, name: value })}
          value={node.name || ''}
        />
      </FormGroup>
      <Border />
      <FormGroup>
        <TextBox
          placeholder="type a user utterance"
          onChange={updateUtterance}
          value={node.utterance}
        />
      </FormGroup>
      <FormGroup>
        <Checkbox
          id={`showAsQuickReply_${node.id}`}
          label="show as a quick reply button"
          checked={node.showAsQuickReply}
          onToggle={toggleShowAsQuickReply}
        />
      </FormGroup>
    </>
  )
}
