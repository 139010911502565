import { NodeTypeDict, NodeType } from '../models/nodes'
import { Automation, AutomationUpdate } from '../models/types'

export function automationReducer(
  model: Automation,
  updates: AutomationUpdate[]
): Automation {
  if (updates[0].type === 'swap') {
    return { ...updates[0].data }
  }

  const clone = { ...model, config: { nodes: [...model.config.nodes] } }
  const nodes = clone.config.nodes
  updates.forEach((action) => {
    const { type, data } = action

    switch (type) {
      case 'update-node':
        nodes[data.index] = data.node
        break

      case 'add-node':
        nodes.push(new NodeTypeDict[data.nodeType as NodeType](data.pos))
        break

      case 'remove-node': {
        const nodeId = nodes[data.index].id
        nodes.forEach((node) => {
          const i = node.children.indexOf(nodeId)
          if (i > -1) {
            node.children.splice(i, 1)
          }
        })
        nodes.splice(data.index, 1)
        break
      }

      case 'add-link':
        nodes[data.originIndex].children.push(nodes[data.destinationIndex].id)
        break

      case 'remove-link':
        {
          const children = nodes[data.originIndex].children
          const i = children.indexOf(nodes[data.destinationIndex].id)
          children.splice(i, 1)
        }
        break

      case 'settings':
        clone.name = data.name
        break
    }
  })

  return clone
}
