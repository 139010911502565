import React, { useMemo } from 'react'
import getHighlightColors from '../../../helpers/getHighlightColors'
import { NodeBase } from '../../../models/nodes'
import { NodeArrowData, SelectionModeData } from '../../../models/types'
import ArrowDefs from './ArrowDefs'
import CanvasArrow from './CanvasArrow'

interface Props {
  nodes: NodeBase[]
  nodeArrowData: Record<string, NodeArrowData> | null
  selectionModeData: SelectionModeData | null
}

const defaultArrow = '#bdc8d9'
const blueArrow = '#3b82f6'
const redArrow = '#f63b82'
export default function CanvasArrows({
  nodes,
  nodeArrowData,
  selectionModeData
}: Props) {
  const renderArrow = (startNode: NodeBase, endNodeId: string, i: number) => {
    if (!nodeArrowData) {
      return
    }

    const startArrowData = nodeArrowData[startNode.id]
    if (!startArrowData) {
      return
    }

    const endArrowData = nodeArrowData[endNodeId]
    if (!endArrowData) {
      return
    }

    const colors = getHighlightColors(nodes, selectionModeData, startNode)
    let arrowHighlight = defaultArrow
    switch (colors.arrowColor) {
      case 'red':
        arrowHighlight = redArrow
        break
      case 'blue':
        arrowHighlight = blueArrow
        break
    }

    return (
      <CanvasArrow
        id={colors.arrowColor || 'default'}
        key={i}
        arrowColor={arrowHighlight}
        startNode={startArrowData}
        endNode={endArrowData}
      />
    )
  }

  const nodesSorted = useMemo(() => {
    return [...nodes].sort((a) => {
      //move highlighted nodes last so arrows appear on top
      return getHighlightColors(nodes, selectionModeData, a).nodeHighlight
        ? 1
        : -1
    })
  }, [nodes, selectionModeData])

  return (
    <svg
      style={{ filter: 'drop-shadow(3px 3px 2px rgb(0 0 0 / 0.4))' }}
      id="arrow-svg"
      className="absolute top-0 left-0 w-full h-full"
    >
      <ArrowDefs id="default" color={defaultArrow} />
      <ArrowDefs id="blue" color={blueArrow} />
      <ArrowDefs id="red" color={redArrow} />
      {nodesSorted.map((node) =>
        node.children.map((child: string, i: number) =>
          renderArrow(node, child, i)
        )
      )}
    </svg>
  )
}
