import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import useRightClick from "../../hooks/useRightClick";
import getHighlightColors from "../../helpers/getHighlightColors";
import useMouseDown from "../../hooks/useMouseDown";
import CanvasGrid from "./CanvasGrid";
import ContextMenu from "../ContextMenu/ContextMenu";
import ContextMenuItem from "../ContextMenu/ContextMenuItem";
import NodeEditorBase from "../EditorWindow/NodeEditorBase";
import Minus from "../Utilities/Icons/Minus";
import {
  getNodeIdFromEventPath,
  getNodeIndexAtPos,
} from "../../helpers/positionHelpers";
import { TransformWrapper } from "react-zoom-pan-pinch";
import Chip from "../Utilities/Icons/Chip";
import Chat from "../Utilities/Icons/Chat";
import Clip from "../Utilities/Icons/Clip";
import GoTo from "../Utilities/Icons/GoTo";
import CanvasToolbar from "../Toolbar/CanvasToolbar";
import SettingsWindow from "../SettingsWindow";
import { automationReducer } from "../../reducers/automationReducer";
import useDebounce from "../../hooks/useDebounce";
import Time from "../Utilities/Icons/Time";
import Stop from "../Utilities/Icons/Stop";
import TestWindow from "../TestChat/TestWindow";
import Flag from "../Utilities/Icons/Flag";
import Pencil from "../Utilities/Icons/Pencil";
import { NodeBase, NodeType } from "../../models/nodes";
import {
  Automation,
  Pos,
  DragNodeData,
  SelectionModeData,
  ContextMenuData,
} from "../../models/types";
import Bolt from "../Utilities/Icons/Bolt";

interface Props {
  selectedAutomation: Automation;
  onSave(updatedAutomation: Automation): void;
}

export default function Canvas({ selectedAutomation, onSave }: Props) {
  const [workingAutomation, dispatchAutomationUpdate] = useReducer(
    automationReducer,
    selectedAutomation,
    (c: Automation) => {
      return { ...c };
    }
  );

  const nodes = useMemo(() => workingAutomation.config.nodes, [
    workingAutomation,
  ]);

  const [el, setEl] = useState<HTMLDivElement | null>(null);
  const [scale, setScale] = useState(1);
  const [draggingNodeData, setDraggingNodeData] = useState<DragNodeData | null>(
    null
  );
  const [
    selectionModeData,
    setSelectionModeData,
  ] = useState<SelectionModeData | null>(null);

  const [
    contextMenuData,
    setContextMenuData,
  ] = useState<ContextMenuData | null>(null);

  const [leftAsideWindow, setLeftAsideWindow] = useState<
    null | "settings" | "test"
  >(null);

  const isDirty = useCallback(() => {
    console.log("RUNNING BAD STUFF");
    //todo: find better solution than constant json comparing
    return (
      JSON.stringify(selectedAutomation) !== JSON.stringify(workingAutomation)
    );
  }, [selectedAutomation, workingAutomation]);

  //trigger Automation swap
  useEffect(() => {
    if (!selectedAutomation || !workingAutomation) {
      return;
    }

    if (selectedAutomation.id !== workingAutomation.id) {
      console.log(
        "detected Automation id change. triggering swap",
        selectedAutomation.id,
        workingAutomation.id
      );
      setSelectionModeData(null);
      setLeftAsideWindow(null);
      if (isDirty()) {
        onSave(workingAutomation);
      }
      dispatchAutomationUpdate([{ type: "swap", data: selectedAutomation }]);
      return;
    }
  }, [isDirty, onSave, selectedAutomation, workingAutomation]);

  const autoSave = useCallback(() => {
    if (!workingAutomation) {
      console.log("checkForChanges - null working skip");
      return false;
    }

    if (selectedAutomation.id !== workingAutomation.id) {
      console.log("checkForChanges - id mismatch skip");

      return false;
    }

    if (isDirty()) {
      onSave(workingAutomation);
    }
  }, [workingAutomation, selectedAutomation.id, isDirty, onSave]);
  useDebounce(autoSave, 5000);

  const onMouseDownCanvas = useCallback(
    (e: MouseEvent) => {
      const clickedOnNode = getNodeIdFromEventPath(
        e.composedPath() as HTMLElement[]
      );

      setContextMenuData(null);
      if (!clickedOnNode && selectionModeData?.selectionType !== "add-node") {
        setSelectionModeData(null);
      }
    },
    [selectionModeData?.selectionType]
  );

  useMouseDown(el, onMouseDownCanvas, false);

  const onRightClickCanvas = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectionModeData(null);
      const pos: Pos = { x: e.clientX, y: e.clientY };
      const nodeId = getNodeIdFromEventPath(e.composedPath() as HTMLElement[]);
      if (nodeId) {
        const index = nodes.findIndex((n: NodeBase) => n.id === nodeId);
        if (nodes[index].type === "StartNode") {
          return;
        }
        setContextMenuData({
          type: "node",
          pos,
          nodeIndex: index,
          allowRemoveNode: true,
        });
      } else {
        setContextMenuData({ type: "canvas", pos });
      }
    },
    [nodes]
  );

  useRightClick(el, onRightClickCanvas);

  const onLeftClickNode = useCallback(
    (i: number) => {
      console.log("onLeftClickNode", i);

      setContextMenuData(null);
      setDraggingNodeData(null);
      if (!selectionModeData || selectionModeData.selectionType === "select") {
        setSelectionModeData({
          originNodeIndex: i,
          selectionType: "select",
        });
        return;
      }

      //only do the thing if that node was selectable
      if (
        selectionModeData.originNodeIndex === undefined ||
        !getHighlightColors(nodes, selectionModeData, nodes[i]).nodeHighlight
      ) {
        return;
      }

      const hasLinkToOrigin = nodes[
        selectionModeData.originNodeIndex
      ].children.includes(nodes[i].id);

      dispatchAutomationUpdate([
        {
          type: hasLinkToOrigin ? "remove-link" : "add-link",
          data: {
            originIndex: selectionModeData.originNodeIndex,
            destinationIndex: i,
          },
        },
      ]);
      setSelectionModeData(null);
    },
    [nodes, selectionModeData]
  );

  const onLeftClickGridItem = useCallback(
    (pos: Pos) => {
      if (
        !selectionModeData ||
        selectionModeData.selectionType !== "add-node" ||
        getNodeIndexAtPos(nodes, pos) > -1
      ) {
        return;
      }

      dispatchAutomationUpdate([
        {
          type: "add-node",
          data: {
            nodeType: selectionModeData.newNodeType,
            pos: pos,
          },
        },
      ]);

      setSelectionModeData(null);
    },
    [selectionModeData, nodes, dispatchAutomationUpdate]
  );

  const onDragNodeStart = useCallback((i: number) => {
    setDraggingNodeData({ init: true, nodeIndex: i, currentPos: null });
  }, []);

  const onDragNodeStop = useCallback(() => {
    if (draggingNodeData?.init) {
      onLeftClickNode(draggingNodeData?.nodeIndex || 0);
    }
    setDraggingNodeData(null);
  }, [draggingNodeData?.init, draggingNodeData?.nodeIndex, onLeftClickNode]);

  const onSwapNode = useCallback(
    (swapIndex: number) => {
      if (!draggingNodeData) {
        throw new Error("missing draggingNodeData");
      }

      const draggingNode = nodes[draggingNodeData.nodeIndex];
      if (!draggingNode) {
        throw new Error("missing draggingNode");
      }

      const swapNode = nodes[swapIndex];
      if (!swapNode) {
        throw new Error("missing swapNode");
      }

      const updates = [];

      updates.push({
        type: "update-node",
        data: {
          index: swapIndex,
          node: {
            ...swapNode,
            pos: { ...draggingNode.pos },
          },
        },
      });

      updates.push({
        type: "update-node",
        data: {
          index: draggingNodeData.nodeIndex,
          node: {
            ...draggingNode,
            pos: swapNode.pos,
          },
        },
      });

      dispatchAutomationUpdate(updates);
      setDraggingNodeData(null);
    },
    [draggingNodeData, nodes, dispatchAutomationUpdate]
  );

  const onDropNode = useCallback(
    (dropPos: Pos, nodeIndex: number) => {
      const existingNodeIndex = getNodeIndexAtPos(nodes, dropPos);
      if (existingNodeIndex > -1) {
        onSwapNode(existingNodeIndex);
        return;
      }

      const draggingNode = nodes[nodeIndex];
      if (!draggingNode) {
        throw new Error("missing draggingNode");
      }

      dispatchAutomationUpdate([
        {
          type: "update-node",
          data: {
            index: nodeIndex,
            node: {
              ...draggingNode,
              pos: dropPos,
            },
          },
        },
      ]);

      setDraggingNodeData(null);
    },
    [nodes, onSwapNode, dispatchAutomationUpdate]
  );

  const onDragEnter = useCallback((pos: Pos) => {
    setDraggingNodeData((prev) => {
      if (!prev) {
        console.error("state null on drag enter");
        return prev;
      }
      return { ...prev, init: false, currentPos: pos };
    });
  }, []);

  const onDragLeave = useCallback(() => {
    setDraggingNodeData((prev) => {
      if (!prev) {
        console.error("state null on drag leave");
        return prev;
      }
      return { ...prev, init: false, currentPos: null };
    });
  }, []);

  const onClickAddNode = useCallback(
    (type: NodeType) => {
      if (!contextMenuData) {
        return;
      }
      setContextMenuData(null);
      setSelectionModeData({
        selectionType: "add-node",
        newNodeType: type,
      });
    },
    [contextMenuData]
  );

  const onClickRemoveNode = useCallback(
    (index?: number) => {
      index = index || contextMenuData?.nodeIndex;
      if (!index) {
        throw new Error("node index undefined on remove node");
      }

      setContextMenuData(null);
      setSelectionModeData(null);
      dispatchAutomationUpdate([
        {
          type: "remove-node",
          data: {
            index,
          },
        },
      ]);
    },
    [contextMenuData, dispatchAutomationUpdate]
  );

  const onUpdateNode = useCallback(
    (node: NodeBase) => {
      if (!selectionModeData) {
        return;
      }
      dispatchAutomationUpdate([
        {
          type: "update-node",
          data: { index: selectionModeData.originNodeIndex, node: node },
        },
      ]);

      if (selectionModeData.selectionType !== "select") {
        setSelectionModeData(null);
      }
    },
    [selectionModeData, dispatchAutomationUpdate]
  );

  const onClickModifyLinks = useCallback(
    (index?: number) => {
      console.log(index);

      index = contextMenuData?.nodeIndex || index;
      if (index === undefined || index === null) {
        throw new Error("node index undefined on add link");
      }

      console.log("modify links", index);

      setSelectionModeData({
        originNodeIndex: index,
        selectionType: "modify-links",
      });
      setContextMenuData(null);
    },
    [contextMenuData]
  );

  // const onClickRemoveLink = useCallback(() => {
  //   if (!contextMenuData || contextMenuData.nodeIndex === undefined) {
  //     throw new Error('contextMenuData?.nodeIndex undefined')
  //   }
  //   setSelectionModeData({
  //     originNodeIndex: contextMenuData.nodeIndex,
  //     selectionType: 'remove-link'
  //   })
  //   setContextMenuData(null)
  // }, [contextMenuData])

  const calcStartPos = useCallback(() => {
    const startRect = document.getElementById("start")?.getBoundingClientRect();

    if (!startRect) {
      return;
    }

    const canvasRect = document
      .getElementById("canvas-grid")
      ?.getBoundingClientRect();
    if (!canvasRect) {
      return;
    }

    const middleX = window.innerWidth / 2;
    const middleY = window.innerHeight / 2;

    const startMidX = startRect.width / 2;
    const startMidY = startRect.height / 2;

    const x = canvasRect.x - startRect.x + middleX - startMidX;
    const y = canvasRect.y - startRect.y + middleY - startMidY;

    return { x, y };
  }, []);

  const setTransformToStartNode = useCallback(
    (setTransform: (x: number, y: number, scale: number) => void) => {
      const pos = calcStartPos();
      if (pos) {
        setTransform(pos.x, pos.y, scale);
      }
    },
    [calcStartPos, scale]
  );

  return (
    <div ref={(el) => setEl(el)} className="w-screen h-screen cursor-move">
      <TransformWrapper
        doubleClick={{ disabled: true }}
        minScale={0.1}
        maxScale={2}
        initialScale={scale}
        onZoomStop={(ref) => {
          setScale(ref.state.scale);
        }}
        disabled={!!draggingNodeData}
        limitToBounds={false}
      >
        {({ setTransform }) => (
          <>
            <CanvasGrid
              scale={scale}
              nodes={nodes}
              draggingNodeData={draggingNodeData}
              selectionModeData={selectionModeData}
              onLoaded={() => {
                setTransformToStartNode(setTransform);
              }}
              onClickModifyLinks={onClickModifyLinks}
              onRemoveNode={onClickRemoveNode}
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onSwapNode={onSwapNode}
              onLeftClickNode={onLeftClickNode}
              onDragNodeStart={onDragNodeStart}
              onDragNodeStop={onDragNodeStop}
              onDropNode={onDropNode}
              onLeftClickGridItem={onLeftClickGridItem}
            />
            <CanvasToolbar
              leftAsideWindow={leftAsideWindow}
              changeLeftAside={setLeftAsideWindow}
              onGoToStart={() => {
                setTransformToStartNode(setTransform);
              }}
              onSave={() => onSave(workingAutomation)}
            />
          </>
        )}
      </TransformWrapper>

      {leftAsideWindow === "test" && (
        <TestWindow automationName={workingAutomation.name} />
      )}
      {leftAsideWindow === "settings" && workingAutomation && (
        <SettingsWindow
          automation={workingAutomation}
          onUpdateName={(name) =>
            dispatchAutomationUpdate([{ type: "settings", data: { name } }])
          }
        />
      )}

      {selectionModeData && selectionModeData.selectionType === "select" && (
        <NodeEditorBase
          node={nodes[selectionModeData.originNodeIndex || 0]}
          onUpdate={onUpdateNode}
        />
      )}

      {contextMenuData && contextMenuData.type === "canvas" && (
        <ContextMenu pos={contextMenuData.pos}>
          <ContextMenuItem onClick={() => onClickAddNode("BotNode")}>
            <>
              <Chip />
              <span className="pl-1">bot says</span>
            </>
          </ContextMenuItem>
          <ContextMenuItem onClick={() => onClickAddNode("UserNode")}>
            <>
              <Chat />
              <span className="pl-1">user says</span>
            </>
          </ContextMenuItem>
          <ContextMenuItem onClick={() => onClickAddNode("DataFieldNode")}>
            <>
              <Clip />
              <span className="pl-1">gather data</span>
            </>
          </ContextMenuItem>
          <ContextMenuItem onClick={() => onClickAddNode("WaitNode")}>
            <>
              <Time />
              <span className="pl-1">wait</span>
            </>
          </ContextMenuItem>
          <ContextMenuItem onClick={() => onClickAddNode("GoToNode")}>
            <>
              <GoTo />
              <span className="pl-1">go to</span>
            </>
          </ContextMenuItem>
          <ContextMenuItem onClick={() => onClickAddNode("CustomNode")}>
            <>
              <Bolt />
              <span className="pl-1">custom action</span>
            </>
          </ContextMenuItem>
          <ContextMenuItem onClick={() => onClickAddNode("ConditionNode")}>
            <>
              <Flag />
              <span className="pl-1">condition</span>
            </>
          </ContextMenuItem>
          <ContextMenuItem onClick={() => onClickAddNode("NoteNode")}>
            <>
              <Pencil />
              <span className="pl-1">note</span>
            </>
          </ContextMenuItem>
          <ContextMenuItem onClick={() => onClickAddNode("StopNode")}>
            <>
              <Stop />
              <span className="pl-1">stop</span>
            </>
          </ContextMenuItem>
        </ContextMenu>
      )}
      {contextMenuData && contextMenuData.type === "node" && (
        <ContextMenu pos={contextMenuData.pos}>
          <>
            {contextMenuData.allowRemoveNode && (
              <ContextMenuItem onClick={() => onClickRemoveNode()}>
                <>
                  <Minus />
                  <span className="pl-1">remove this node</span>
                </>
              </ContextMenuItem>
            )}
          </>
          {/* <ContextMenuItem onClick={() => onClickModifyLinks()}>
            <>
              <Plus />
              <span className="pl-1">modify links</span>
            </>
          </ContextMenuItem> */}
          {/* <ContextMenuItem onClick={() => onClickRemoveLink()}>
            <>
              <Minus />
              <span className="pl-1">remove a link</span>
            </>
          </ContextMenuItem> */}
        </ContextMenu>
      )}
    </div>
  );
}
