import React from 'react'
import LoadingSpinner from '../Utilities/LoadingSpinner'
import useAuth from '../../hooks/useAuth'

export default function LoginCallback() {
  const auth = useAuth()

  React.useLayoutEffect(() => {
    auth.handleLoginCallback()
  }, [auth])

  return <LoadingSpinner />
}
