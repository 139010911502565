import React from 'react'

interface Props {
  children: JSX.Element | JSX.Element[]
  label?: string
}
export default function FormGroup({ children, label }: Props) {
  return (
    <>
      {label && <label className="relative mb-2">{label}</label>}
      <div className="relative mb-4">{children}</div>
    </>
  )
}
