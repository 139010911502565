import React, { useCallback, useState } from 'react'
import useLeftClick from '../../../../hooks/useLeftClick'
import useMouseDown from '../../../../hooks/useMouseDown'
import useDraggable from '../../../../hooks/useDraggable'
import useMouseUp from '../../../../hooks/useMouseUp'
import { NodeBase } from '../../../../models/nodes'
import Trash from '../../../Utilities/Icons/Trash'
import CanvasNodeContextButton from './CanvasNodeContextButton'
import Link from '../../../Utilities/Icons/Link'
import Tooltip from '../../../Utilities/Tooltip'

interface Props {
  node: NodeBase
  highlightColor?: string
  selected: boolean
  hovering: boolean
  onRemove(): void
  onAddLink(): void
  onLeftClick(): void
  onDragStart(): void
  onDragStop(): void
  onLoad(el: HTMLDivElement): void
}

export default function CanvasNode({
  node,
  highlightColor,
  hovering,
  selected,
  onRemove,
  onAddLink,
  onLeftClick,
  onDragStart,
  onDragStop,
  onLoad
}: Props) {
  const [el, setEl] = useState(null)
  useLeftClick(el, onLeftClick)
  useMouseDown(el, onDragStart, false)
  useMouseUp(el, onDragStop)
  useDraggable(el, () => null, onDragStop)

  const onRender = useCallback(
    (el: any) => {
      if (!el) {
        return
      }

      setEl(el)
      onLoad(el)
      return
    },
    [onLoad]
  )

  const canDelete = node.type !== 'StartNode'
  return (
    <div
      className="absolute z-40 leading-normal break-words opacity-90 cursor-pointer node"
      draggable={true}
      id={node.id.toString()}
      ref={onRender}
    >
      <div className="relative">
        {(selected || hovering) && (
          <>
            {canDelete && (
              <div className="absolute -top-9 left-1">
                <Tooltip text="Delete node">
                  <CanvasNodeContextButton onClick={onRemove}>
                    <Trash />
                  </CanvasNodeContextButton>
                </Tooltip>
              </div>
            )}
            <div className="absolute -top-9 right-1">
              <Tooltip text="Update links">
                <CanvasNodeContextButton onClick={onAddLink}>
                  <Link />
                </CanvasNodeContextButton>
              </Tooltip>
            </div>
          </>
        )}
        {node.getNodeContent({ highlightColor })}
      </div>
    </div>
  )
}
