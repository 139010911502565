import React, { useState } from 'react'
import useLeftClick from '../hooks/useLeftClick'
import useMouseDown from '../hooks/useMouseDown'
import useRightClick from '../hooks/useRightClick'
import { Automation } from '../models/types'
import FormGroup from './Utilities/FormGroup'
import TextBox from './Utilities/TextBox'

interface Props {
  automation: Automation
  onUpdateName(name: string): void
}

export default function SettingsWindow({ automation, onUpdateName }: Props) {
  const [el, setEl] = useState<HTMLDivElement | null>(null)

  useLeftClick(el, () => null)
  useRightClick(el, () => null)
  useMouseDown(el, () => null)

  return (
    <div
      ref={(el) => setEl(el)}
      style={{ width: '400px', minHeight: '500px' }}
      className="fixed inset-y-28 left-10 z-40 p-3 w-16 bg-gradient-to-r from-gray-100 to-gray-50 shadow-lg cursor-default"
    >
      <div className="w-full h-full">
        <div className="mb-5 w-full text-lg text-center border-b">Settings</div>
        <FormGroup>
          <TextBox
            placeholder="name"
            onChange={onUpdateName}
            value={automation.name || ''}
            maxlength={50}
          />
        </FormGroup>
      </div>
    </div>
  )
}
