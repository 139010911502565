import React, { useMemo } from 'react'
import Clickable from '../Utilities/Clickable'
import ForegroundPanel from '../Utilities/ForegroundPanel'
import ArrowsExpand from '../Utilities/Icons/ArrowsExpand'
import Beaker from '../Utilities/Icons/Beaker'
import Cog from '../Utilities/Icons/Cog'
import Save from '../Utilities/Icons/Save'
import Tooltip from '../Utilities/Tooltip'
import Toolbar from './Toolbar'

interface Props {
  leftAsideWindow: null | 'settings' | 'test'
  changeLeftAside(value: null | 'settings' | 'test'): void
  onGoToStart(): void
  onSave(): void
}

export default function CanvasToolbar({
  leftAsideWindow,
  changeLeftAside,
  onGoToStart,
  onSave
}: Props) {
  const settingsOpen = useMemo(
    () => leftAsideWindow === 'settings',
    [leftAsideWindow]
  )
  const testChatOpen = useMemo(
    () => leftAsideWindow === 'test',
    [leftAsideWindow]
  )

  const items = useMemo(() => {
    return [
      () => (
        <Tooltip text={settingsOpen ? 'Hide settings' : 'Show settings'}>
          <Clickable
            onClick={() => changeLeftAside(settingsOpen ? null : 'settings')}
            active={settingsOpen}
          >
            <Cog />
          </Clickable>
        </Tooltip>
      ),
      () => (
        <Tooltip text={testChatOpen ? 'Hide test chat' : 'Show test chat'}>
          <Clickable
            onClick={() => changeLeftAside(testChatOpen ? null : 'test')}
            active={testChatOpen}
          >
            <Beaker />
          </Clickable>
        </Tooltip>
      ),
      () => (
        <Tooltip text={'Go to start'}>
          <Clickable onClick={onGoToStart}>
            <ArrowsExpand />
          </Clickable>
        </Tooltip>
      ),

      () => (
        <Tooltip text={'Save changes'}>
          <Clickable onClick={onSave}>
            <Save />
          </Clickable>
        </Tooltip>
      )
    ]
  }, [changeLeftAside, onGoToStart, onSave, settingsOpen, testChatOpen])

  return (
    <div className="fixed bottom-10 left-1/2 scale-150 -translate-x-1/2">
      <Toolbar items={items} />
    </div>
  )
}
