import { useEffect } from 'react'

export default function useDroppable(
  el: HTMLElement | null,
  onDragEnter: (e: DragEvent) => void,
  onDragLeave: (e: DragEvent) => void,
  onDrop: (e: DragEvent) => void
) {
  useEffect(() => {
    if (!el) {
      return
    }

    const onDragEnterEvent = function (e: DragEvent) {
      onDragEnter(e)
    }

    const onDragOver = function (e: DragEvent) {
      e.preventDefault()
      if (e.dataTransfer) {
        e.dataTransfer.dropEffect = 'move'
      }
    }

    const onDragLeaveEvent = function (e: DragEvent) {
      e.preventDefault()
      onDragLeave(e)
    }

    const onDropEvent = function (e: DragEvent) {
      onDrop(e)
    }

    el.addEventListener('dragenter', onDragEnterEvent)
    el.addEventListener('dragover', onDragOver)
    el.addEventListener('dragleave', onDragLeaveEvent)
    el.addEventListener('drop', onDropEvent)
    return () => {
      el.removeEventListener('dragenter', onDragEnterEvent)
      el.removeEventListener('dragover', onDragOver)
      el.removeEventListener('dragleave', onDragLeaveEvent)
      el.removeEventListener('drop', onDropEvent)
    }
  }, [onDragLeave, onDragEnter, onDrop, el])
}
