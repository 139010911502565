import React from 'react'
import { NodeOptions, StopNode } from '../../../models/nodes'
import Stop from '../../Utilities/Icons/Stop'
import RectangleNode from './shared/RectangleNode'
import { StopActionDefs } from '../../../models/types'

interface Props {
  node: StopNode
  options: NodeOptions
}

export default function CanvasStopNode({ node, options }: Props) {
  return (
    <RectangleNode
      bg={'bg-red-50'}
      border={'border-red-800'}
      icon={<Stop color="text-red-800" />}
      highlightColor={options.highlightColor}
    >
      <div id="stop">
        <div className={`flex flex-wrap tracking-wide px-2 py-4`}>
          <span className="text-lg font-bold">
            {StopActionDefs[node.stopAction]}
          </span>
        </div>
      </div>
    </RectangleNode>
  )
}
