import React from 'react'
import { NoteNode } from '../../models/nodes'
import FormGroup from '../Utilities/FormGroup'
import TextArea from '../Utilities/TextArea'

interface Props {
  node: NoteNode
  update(node: NoteNode): void
}

export default function NoteNodeEditor({ node, update }: Props) {
  return (
    <FormGroup>
      <TextArea
        placeholder="note..."
        onChange={(value) => update({ ...node, val: value })}
        value={node.val || ''}
      />
    </FormGroup>
  )
}
