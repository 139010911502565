import { StartJob, JobOutput, ContinueJob, StopJob } from '../models/api'
import AuthService from './AuthService'
import RestService from './RestService'

const url = `${import.meta.env.VITE_API_URL}/job`

const api = new RestService({
  rootUrl: url,
  tokenAccessor: () => AuthService.token.token
})
export async function startJob(payload: StartJob): Promise<JobOutput> {
  return await api.post('start', payload).then((response) => response.json())
}

export async function continueJob(payload: ContinueJob): Promise<JobOutput> {
  return await api.post('continue', payload).then((response) => response.json())
}

export async function stopJob(payload: StopJob): Promise<JobOutput> {
  return await api.post('stop', payload).then((response) => response.json())
}
