import React, { useMemo } from "react";
import { ConditionNode } from "../../models/nodes";
import { Condition, comparators, Comparator } from "../../models/types";
import Border from "../Utilities/Border";
import FormGroup from "../Utilities/FormGroup";
import FormGroupInline from "../Utilities/FormGroupInline";
import NumberBox from "../Utilities/NumberBox";
import Select from "../Utilities/Select";
import TextBox from "../Utilities/TextBox";

interface Props {
  node: ConditionNode;
  update(node: ConditionNode): void;
}

export default function ConditionNodeEditor({ node, update }: Props) {
  function updateNode(updates: Partial<ConditionNode>) {
    update({ ...node, ...updates });
  }

  function updateCondition(updates: Partial<Condition>) {
    update({
      ...node,
      condition: { ...node.condition, ...updates },
    });
  }

  const showValue = useMemo(() => {
    const valuelessComparators = ["missing", "exists", "valid", "invalid"];
    return !valuelessComparators.includes(node.condition.comparator);
  }, [node.condition.comparator]);

  return (
    <>
      <FormGroup>
        <TextBox
          placeholder="node name"
          onChange={(value) => updateNode({ name: value })}
          value={node.name || ""}
        />
      </FormGroup>
      <Border />
      <FormGroup>
        <NumberBox
          label="priority"
          min={1}
          max={99}
          onChange={(value) =>
            updateCondition({ priority: value < 1 ? 1 : value })
          }
          value={node.condition.priority}
        />
      </FormGroup>
      <FormGroup>
        <TextBox
          placeholder="data name"
          onChange={(value) => updateCondition({ ...node, data: value })}
          value={node.condition.data || ""}
        />
      </FormGroup>
      <FormGroupInline>
        <>
          <Select
            options={comparators}
            value={node.condition.comparator}
            onChange={(value: Comparator) =>
              updateCondition({ ...node, comparator: value })
            }
          />
          {showValue && (
            <TextBox
              placeholder="value"
              onChange={(value) => updateCondition({ ...node, value: value })}
              value={node.condition.value || ""}
            />
          )}
        </>
      </FormGroupInline>
    </>
  );
}
