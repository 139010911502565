import React from 'react'
import useAuth from './hooks/useAuth'
import LoadingSpinner from './components/Utilities/LoadingSpinner'
import App from './components/App'
import { DataSetProvider } from './providers/DataSetProvider'
import useWarmup from './hooks/useWarmup'
import Warmup from '@velaro/velaro-shared/src/components/Warmup'

export default function Main() {
  const { warmedUp } = useWarmup()
  const auth = useAuth()

  console.log('warmedUp', warmedUp)

  if (!warmedUp) {
    return <Warmup />
  }

  if (!auth.isAuthenticated()) {
    console.log('not authenticated')
    auth.loginRedirect()
    return <LoadingSpinner />
  }
  return (
    <DataSetProvider>
      <App />
    </DataSetProvider>
  )
}
