interface Token {
  token: string
  expires: Date
}

class AuthService {
  constructor() {
    this.loadTokenFromStorage()
    this.loadLegacyTokenFromStorage()
    this.loadProfileFromStorage()
  }

  token: Token = {
    token: '',
    expires: new Date(Date.now() - 1000)
  }

  legacyToken: Token = {
    token: '',
    expires: new Date(Date.now() - 1000)
  }

  profile = {
    id: '',
    userName: '',
    picture: '',
    userId: 0,
    siteId: 0
  }

  loadProfileFromStorage() {
    const profileJson = sessionStorage.getItem('profile')

    if (profileJson) {
      this.profile = JSON.parse(profileJson)
    }
  }

  loadTokenFromStorage() {
    const item = sessionStorage.getItem('token')

    if (!item) {
      return
    }

    const data = JSON.parse(item)

    this.token = {
      token: data.token,
      expires: new Date(data.expires)
    }
  }

  loadLegacyTokenFromStorage() {
    const item = sessionStorage.getItem('legacyToken')

    if (!item) {
      return
    }

    const data = JSON.parse(item)

    this.legacyToken = {
      token: data.token,
      expires: new Date(data.expires)
    }
  }

  isAuthenticated() {
    if (!this.token) {
      return false
    }

    return this.token.expires.getTime() > Date.now() && this.token.token !== ''
  }

  retryLogin() {
    const url = sessionStorage.getItem('redirectUri') || '/'
    window.location.replace(url)
  }

  loginRedirect() {
    sessionStorage.setItem('redirectUri', window.location.pathname)
    const redirectUri = `${window.location.origin}/login/callback`
    const qs = `redirectUri=${encodeURIComponent(redirectUri)}`
    const url = `${import.meta.env.VITE_AUTH_URL}/identity/authenticate?${qs}`
    window.location.replace(url)
  }

  logout() {
    const redirectUri = `${window.location.origin}/logout/callback`
    const qs = `redirectUri=${encodeURIComponent(redirectUri)}`
    const url = `${import.meta.env.VITE_AUTH_URL}/account/logout?${qs}`
    window.location.replace(url)
  }

  logoutCallback() {
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('legacyToken')
    window.location.replace('/')
  }

  async refresh(temporaryToken: string) {
    await this.fetchAccessToken(temporaryToken)
    console.log('auth token refreshed')
  }

  async fetchAccessToken(temporaryToken: string) {
    const response = await fetch(`${import.meta.env.VITE_API_URL}/token`, {
      method: 'post',
      body: JSON.stringify({ temporaryToken }),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    if (!response.ok) {
      console.error('unable to acquire access token')
      return response
    }

    const data = await response.json()

    this.token = {
      token: data.token.token,
      expires: new Date(data.token.expires)
    }

    this.legacyToken = {
      token: data.legacyToken.token,
      expires: new Date(data.legacyToken.expires)
    }

    this.profile = data.profile

    sessionStorage.setItem('token', JSON.stringify(this.token))
    sessionStorage.setItem('legacyToken', JSON.stringify(this.legacyToken))
    sessionStorage.setItem('profile', JSON.stringify(data.profile))
    console.log('access token acquired')

    return response
  }

  async handleLoginCallback() {
    const params = new URLSearchParams(window.location.search)
    const temporaryToken = params.get('token')!
    try {
      const response = await this.fetchAccessToken(temporaryToken)

      if (response.ok) {
        const redirectUri = sessionStorage.getItem('redirectUri')
        console.log('login callback success', redirectUri)
        window.location.replace(redirectUri!)
      } else {
        if (response.status === 401) {
          window.location.replace('/login/unauthorized')
        } else {
          console.error((await response.json()).error)
        }
      }
    } catch (err) {
      window.location.replace('/login/callback/error')
    }
  }
}

const authService = new AuthService()

function silentRenew() {
  let frameRemoved = false

  console.log('silent renew')
  const frame = document.createElement('iframe')
  const qs = `originUri=${encodeURIComponent(window.location.origin)}`
  frame.src = `${import.meta.env.VITE_AUTH_URL}/identity/silentrenew?${qs}`
  frame.style.width = '0'
  frame.style.height = '0'
  frame.style.border = '0'
  frame.style.border = 'none'
  frame.style.position = 'absolute'

  const destroy = () => {
    document.body.removeChild(frame)
    window.removeEventListener('message', handleMessage)
    frameRemoved = true
  }

  const handleMessage = (message: any) => {
    try {
      if (message.origin === import.meta.env.VITE_AUTH_URL) {
        const data = JSON.parse(message.data)
        if (data.type === 'silentrenew') {
          if (data.isAuthenticated) {
            authService.refresh(data.token)
          } else {
            authService.logout()
          }

          destroy()
        }
      }
    } catch (err) {
      console.error(err)
      destroy()
    }
  }

  window.addEventListener('message', handleMessage)
  document.body.appendChild(frame)

  setTimeout(() => {
    if (!frameRemoved) {
      console.log('silent renew timeout')
      destroy()
    }
  }, 30000)
}

let checkAccessTokenTimeout: any

/**
 * Check the access token to see if it needs to be refreshed.
 * When the token is close to expiration, a call will be made to renew it.
 */

function checkAccessToken() {
  console.log('checking token expiration')
  const expires = authService.token.expires
  const delta = expires.getTime() - Date.now() - 1000 * 60 * 10
  console.log(`token expires in ${expires.getTime() - Date.now()}`)
  console.log(`token expiry delta ${delta}`)

  if (delta < 0) {
    console.log('token expires soon, renewing')
    silentRenew()
  }

  clearTimeout(checkAccessTokenTimeout)
  checkAccessTokenTimeout = setTimeout(() => checkAccessToken(), 30000)
}

window.addEventListener('focus', () => {
  checkAccessToken()
})

checkAccessTokenTimeout = setTimeout(() => checkAccessToken(), 30000)

export default authService
