import { useEffect } from 'react'

export default function useMouseUp(
  el: HTMLElement | null,
  onClick: (e: MouseEvent) => void
) {
  useEffect(() => {
    if (!el) {
      return
    }

    const onMouseUp = function (e: MouseEvent) {
      onClick(e)
    }

    el.addEventListener('mouseup', onMouseUp)
    return () => {
      el.removeEventListener('mouseup', onMouseUp)
    }
  }, [el, onClick])
}
