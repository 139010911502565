import React, { useState } from 'react'
import Button from './Utilities/Button'
import TextBox from './Utilities/TextBox'
import Select from './Utilities/Select'
import { Template, templateOptions } from '../models/templates'

interface Props {
  onConfirm(template: Template, name: string): void
  onCancel?(): void
  isFirst: boolean
}

export default function NewAutomationModal({
  onConfirm,
  onCancel,
  isFirst
}: Props) {
  const [name, setName] = useState<string | undefined>(undefined)
  const [nameMissing, setNameMissing] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState<Template>(
    Object.keys(templateOptions)[0] as Template
  )

  function onClickConfirm() {
    setNameMissing(false)
    if (!name) {
      setNameMissing(true)
      return
    }
    onConfirm(selectedTemplate, name)
  }

  const label = isFirst
    ? 'Hello. To get started with your first automation, select a starting template that best describes your use case and give it a name.'
    : 'Start a new automation'

  return (
    <div
      style={{ width: '450px' }}
      className="flex fixed top-1/2 left-1/2 flex-col p-8 text-lg bg-gradient-to-r from-gray-100 to-gray-50 shadow-lg -translate-x-1/2 -translate-y-1/2"
    >
      {label}
      <div className="my-4">
        <Select
          options={templateOptions}
          value={selectedTemplate}
          onChange={setSelectedTemplate}
        />
      </div>
      <div className={`my-4 rounded-lg `}>
        <TextBox
          value={name || ''}
          onChange={setName}
          placeholder="Name"
          notValid={nameMissing}
        />
      </div>
      <div className="flex flex-row justify-between">
        {!isFirst && onCancel && (
          <Button text="Cancel" textSize="text-xl" onClick={onCancel} />
        )}
        <Button text="Create" textSize="text-xl" onClick={onClickConfirm} />
      </div>
    </div>
  )
}
