import React, { useCallback } from 'react'
import useAuth from '../hooks/useAuth'
import LoadingSpinner from '../components/Utilities/LoadingSpinner'

export default function usePrivateRoute() {
  const auth = useAuth()

  const renderPrivateRoute = useCallback(
    (Component: any) => {
      if (!!Component && auth.isAuthenticated()) {
        return <Component />
      } else {
        console.log('Not authenticated, redirecting to login')
        auth.loginRedirect()
        return <LoadingSpinner />
      }
    },
    [auth]
  )

  return { renderPrivateRoute }
}
