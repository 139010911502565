import React from 'react'
import { NodeBase } from '../../models/nodes'
import ForegroundPanel from '../Utilities/ForegroundPanel'

interface Props {
  node: NodeBase
  onUpdate(node: NodeBase): void
}

export default function NodeEditorBase({ node, onUpdate }: Props) {
  return (
    <div
      style={{ width: '400px', minHeight: '500px' }}
      id="node-editor-base"
      className="fixed inset-y-28 right-10 z-40 p-3 w-16"
    >
      <ForegroundPanel>
        <div className="relative w-full h-full">
          <div className="mb-5 w-full text-lg text-center border-b">
            {node.title}
          </div>
          {node.getEditorContent(onUpdate)}

          <div className="absolute right-0 bottom-0 text-xs font-light">
            {node.id}
          </div>
        </div>
      </ForegroundPanel>
    </div>
  )
}
