import React from 'react'

interface Props {
  children: string
}

export default function TestSystemMessage({ children }: Props) {
  return (
    <div className="p-3 mb-4 leading-7 text-center bg-yellow-100 rounded-2xl">
      {children}
    </div>
  )
}
