import React, { Key, useCallback } from 'react'

type SelectType =
  | string[]
  | number[]
  | readonly string[]
  | SelectOption[]
  | Record<string | number, any>

class SelectOption {
  key!: SelectKey
  value!: any
}

type SelectKey = string | number | readonly string[] | undefined

interface Props {
  options: SelectType
  value: any
  onChange(value: any): void
  width?: string
}

export default function Select({ options, value, onChange, width }: Props) {
  width = width || 'w-full'
  const renderOptions = useCallback(() => {
    if (Array.isArray(options)) {
      console.log('A')

      return options.map((option) => {
        if (typeof option !== 'string') {
          option = option as SelectOption
          return (
            <option key={option.key} value={option.key}>
              {option.value}
            </option>
          )
        } else {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          )
        }
      })
    } else {
      return Object.keys(options).map((key, i) => {
        return (
          <option key={i} value={key}>
            {(options as Record<string | number, string>)[key]}
          </option>
        )
      })
    }
  }, [options])

  return (
    <select
      className={`inline p-2 ${width} text-sm rounded-lg border shadow-sm`}
      onChange={(e) => onChange(e.currentTarget.value)}
      value={value}
    >
      {renderOptions()}
    </select>
  )
}
