import React from 'react'
import { CustomNode } from '../../models/nodes'
import { CustomActionData } from '../../models/types'
import Border from '../Utilities/Border'
import Button from '../Utilities/Button'
import Clickable from '../Utilities/Clickable'
import FormGroup from '../Utilities/FormGroup'
import FormGroupInline from '../Utilities/FormGroupInline'
import X from '../Utilities/Icons/X'
import Select from '../Utilities/Select'
import TextBox from '../Utilities/TextBox'
import Tooltip from '../Utilities/Tooltip'

interface Props {
  node: CustomNode
  update(node: CustomNode): void
}

const methodOptions = ['POST', 'PUT', 'GET', 'DELTE'].map((m) => {
  return { key: m, value: m }
})

export default function CustomNodeEditor({ node, update }: Props) {
  function updateNode(updates: Partial<CustomNode>) {
    update({ ...node, ...updates })
  }

  function updateCustomActionData(updates: Partial<CustomActionData>) {
    update({
      ...node,
      customActionData: { ...node.customActionData, ...updates }
    })
  }

  return (
    <>
      <FormGroup>
        <TextBox
          placeholder="node name"
          onChange={(value) => updateNode({ name: value })}
          value={node.name || ''}
        />
      </FormGroup>
      <Border />
      <FormGroup>
        <Select
          options={methodOptions}
          onChange={(value) =>
            updateCustomActionData({
              method: value.toString()
            })
          }
          value={node.customActionData.method || 'POST'}
        />
      </FormGroup>
      <FormGroup>
        <TextBox
          placeholder="url"
          onChange={(value) =>
            updateCustomActionData({
              url: value.toString()
            })
          }
          value={node.customActionData.url || ''}
        />
      </FormGroup>
      <FormGroup>
        <Button
          text="Add data"
          onClick={() => {
            updateCustomActionData({
              data: [...node.customActionData.data, '']
            })
          }}
        />
      </FormGroup>
      {node.customActionData.data.map((data, i) => (
        <div className="" key={i}>
          <FormGroupInline>
            <TextBox
              width="w-full"
              placeholder="name"
              onChange={(val) => {
                const clone = [...node.customActionData.data]
                clone[i] = val
                updateCustomActionData({ data: clone })
              }}
              value={data}
            />
            <Tooltip text="remove">
              <Clickable
                onClick={() => {
                  const clone = [...node.customActionData.data]
                  clone.splice(i, 1)
                  updateCustomActionData({ data: clone })
                }}
              >
                <span className="text-red-500">
                  <X />
                </span>
              </Clickable>
            </Tooltip>
          </FormGroupInline>
        </div>
      ))}
    </>
  )
}
