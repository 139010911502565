import React from 'react'

interface Props {
  id: string
  color: string
}

export default function ArrowDefs({ id, color }: Props) {
  return (
    <defs>
      <marker
        id={'arrow-start_' + id}
        viewBox="0 0 10 10"
        refX="5"
        refY="5"
        orient="auto"
      ></marker>
      <marker id={'arrow_' + id} refX="9" refY="5" orient="auto">
        <path d="M0,0 L0,10 L10,5 L0,0" style={{ fill: color }} />
      </marker>

      <marker id={'arrow-joint_' + id} viewBox="0 0 10 10" refX="5" refY="5">
        <circle cx="5" cy="5" r="1.5" style={{ fill: color }} />
      </marker>

      <marker
        id={'arrow-end_' + id}
        viewBox="0 0 10 10"
        refX="0"
        refY="5"
        orient="auto"
      >
        <path d="M 0 0 L 10 5 L 0 10 z" style={{ fill: color }} />
      </marker>
    </defs>
  )
}
