import React from 'react'
import { NodeOptions } from '../../../models/nodes'
import Star from '../../Utilities/Icons/Star'
import RectangleNode from './shared/RectangleNode'

interface Props {
  options: NodeOptions
}

export default function CanvasStartNode({ options }: Props) {
  return (
    <RectangleNode
      bg={'bg-green-50'}
      border={'border-green-800'}
      icon={<Star color="text-green-800" />}
      highlightColor={options.highlightColor}
    >
      <div id="start">
        <div className="flex justify-around items-center text-sm leading-8 ">
          <div className="absolute left-2 opacity-70"></div>
          <div className={`flex flex-wrap tracking-wide px-2 py-4`}>
            <span className="text-lg font-bold">Start</span>
          </div>
        </div>
      </div>
    </RectangleNode>
  )
}
