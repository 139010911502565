import React, { useCallback, useMemo } from 'react'
import { Automation } from '../../models/types'
import Clickable from '../Utilities/Clickable'
import ForegroundPanel from '../Utilities/ForegroundPanel'
import Plus from '../Utilities/Icons/Plus'
import Trash from '../Utilities/Icons/Trash'
import Select from '../Utilities/Select'
import Tooltip from '../Utilities/Tooltip'
import Toolbar from './Toolbar'

interface Props {
  allAutomations: Automation[]
  selectedIndex: number
  onSelectAutomation(id: number): void
  onNewAutomation(): void
  onDeleteAutomation(): void
}

export default function AutomationSelectToolbar({
  allAutomations,
  selectedIndex,
  onSelectAutomation,
  onNewAutomation,
  onDeleteAutomation
}: Props) {
  const options = useMemo(() => {
    return allAutomations.map((b, i) => {
      return {
        key: i,
        value: b.name
      }
    })
  }, [allAutomations])

  const onChange = useCallback(
    (val: string) => {
      onSelectAutomation(Number(val))
    },
    [onSelectAutomation]
  )

  const items = useMemo(() => {
    return [
      () => (
        <select
          className={`inline text-sm bg-gray-100 border-none`}
          onChange={(e) => onChange(e.currentTarget.value)}
          value={selectedIndex}
        >
          {options.map((option) => {
            return (
              <option key={option.key} value={option.key}>
                {option.value}
              </option>
            )
          })}
        </select>
      ),
      () => (
        <Tooltip text="Start a new automation">
          <Clickable onClick={onNewAutomation}>
            <Plus />
          </Clickable>
        </Tooltip>
      ),
      () => (
        <Tooltip text="Delete this automation">
          <Clickable onClick={onDeleteAutomation}>
            <Trash />
          </Clickable>
        </Tooltip>
      )
    ]
  }, [onChange, onDeleteAutomation, onNewAutomation, options, selectedIndex])

  return (
    <div className="fixed bottom-10 left-1/4 scale-150 -translate-x-1/2">
      <Toolbar items={items} />
    </div>
  )
}
