import React, { useCallback, useEffect, useState } from 'react'
import { DataSetContext } from '../context/DataSetContext'
import { DataSet } from '../models/types'
import { getAll } from '../services/DataSetService'

export function DataSetProvider(props: { children: React.ReactNode }) {
  const [loading, setDataSetsLoading] = useState(true)
  const [dataSets, setDataSets] = useState<DataSet[]>([])

  const fetchDataSets = useCallback(async () => {
    try {
      const all = await getAll()
      setDataSets(all)
    } catch (error) {
      console.error('Failed to fetch DataSets', error)
    } finally {
      setDataSetsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchDataSets()
  }, [fetchDataSets])

  return (
    <DataSetContext.Provider value={{ loading, dataSets: dataSets }}>
      {props.children}
    </DataSetContext.Provider>
  )
}
