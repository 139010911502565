import React from 'react'

interface Props {
  children: React.ReactNode
  onClick: () => void
}

export default function CanvasNodeContextButton({ children, onClick }: Props) {
  return (
    <div onClick={onClick} className="p-1 text-white scale-150 cursor-pointer">
      {children}
    </div>
  )
}
