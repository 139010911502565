import React, { useCallback } from 'react'

interface Props {
  placeholder?: string
  disabled?: boolean
  value: string
  onChange(value: string): void
  onEnter?(): void
}

export default function TextArea({
  placeholder,
  disabled,
  value,
  onChange,
  onEnter
}: Props) {
  const onKeyUp = useCallback(
    (e: any) => {
      if (e.key === 'Enter' && onEnter) {
        e.preventDefault()
        onEnter()
        return
      }
    },
    [onEnter]
  )

  return (
    <textarea
      placeholder={placeholder}
      disabled={disabled}
      className="p-2 w-full max-h-60 text-sm rounded-lg border shadow-sm"
      onChange={(e) => onChange(e.currentTarget.value)}
      value={value}
      onKeyUp={(e) => onKeyUp(e)}
    />
  )
}
