import { useEffect } from 'react'

const clickableClasses = ['app', 'node']
export default function useMouseDown(
  el: HTMLElement | null,
  onClick: (e: MouseEvent) => void,
  stopPropagation = true
) {
  useEffect(() => {
    if (!el) {
      return
    }

    const onMouseDown = function (e: MouseEvent) {
      const path = e.composedPath() as HTMLElement[]
      const containsClickableClass = path.some((el) => {
        return clickableClasses.some(
          (c) => el.classList && el.classList.contains(c)
        )
      })

      if (!containsClickableClass) {
        return
      }

      if (stopPropagation) {
        e.stopPropagation()
      }

      onClick(e)
    }

    el.addEventListener('mousedown', onMouseDown)
    return () => {
      el.removeEventListener('mousedown', onMouseDown)
    }
  }, [el, onClick, stopPropagation])
}
