import React from 'react'
import { NoteNode, NodeOptions } from '../../../models/nodes'
import RectangleNode from './shared/RectangleNode'

interface Props {
  node: NoteNode
  options: NodeOptions
}

export default function CanvasNoteNode({ node, options }: Props) {
  return (
    <RectangleNode
      bg="bg-yellow-50"
      border="border-yellow-400"
      highlightColor={options.highlightColor}
    >
      <div className="text-sm break-words">
        <p className={`flex flex-wrap px-2 py-4 `}>{node.val}</p>
      </div>
    </RectangleNode>
  )
}
