import React from 'react'

interface Props {
  children: JSX.Element
  active?: boolean
  onClick(): void
}

export default function Clickable({ children, active, onClick }: Props) {
  return (
    <div
      onClick={onClick}
      className={`flex hover:scale-125 active:text-blue-800 cursor-pointer${
        active ? ' text-blue-500' : ''
      }`}
    >
      {children}
    </div>
  )
}
