import React, { ReactElement, useCallback, useState } from 'react'
import useMouseDown from '../../hooks/useMouseDown'

interface Props {
  onClick(): void
  children: ReactElement | string
}
export default function ContextMenuItem({ children, onClick }: Props) {
  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  const onRender = useCallback((el: any) => {
    setRef(el)
  }, [])
  useMouseDown(ref, onClick)
  return (
    <div
      ref={onRender}
      className="flex py-1 px-2 text-gray-600 hover:text-blue-600 hover:bg-blue-100 rounded cursor-pointer"
    >
      {children}
    </div>
  )
}
