import React, { useMemo } from "react";
import { ConditionNode, NodeOptions } from "../../../models/nodes";
import { Tag } from "../../Utilities/Tag";
import RoundedNode from "./shared/RoundedNode";

interface Props {
  node: ConditionNode;
  options: NodeOptions;
}

export default function CanvasConditionNode({ node, options }: Props) {
  const showValue = useMemo(() => {
    const valuelessComparators = ["missing", "exists", "valid", "invalid"];
    return !valuelessComparators.includes(node.condition.comparator);
  }, [node.condition.comparator]);

  return (
    <RoundedNode
      highlightColor={options.highlightColor}
      bg="bg-fuchsia-50"
      border="border-fuchsia-500"
    >
      <div
        className={`flex flex-row flex-wrap items-center align-middle gap-x-4 justify-center px-2 py-4`}
      >
        <div className="text-lg">If</div>
        <Tag>{node.condition.data}</Tag>
        <div className="text-lg">{node.condition.comparator}</div>
        {showValue && (
          <div className="text-lg font-bold">{node.condition.value}</div>
        )}
      </div>
    </RoundedNode>
  );
}
