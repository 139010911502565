import React, { useCallback } from "react";
import { DataFieldNode } from "../../models/nodes";
import {
  DataField,
  DataFieldType,
  DataFieldTypeDict,
  DataMode,
} from "../../models/types";

import Border from "../Utilities/Border";
import Clickable from "../Utilities/Clickable";
import FormGroup from "../Utilities/FormGroup";
import FormGroupInline from "../Utilities/FormGroupInline";
import X from "../Utilities/Icons/X";
import Select from "../Utilities/Select";
import TextBox from "../Utilities/TextBox";
import ToggleButtonGroup from "../Utilities/ToggleButtonGroup";
import Tooltip from "../Utilities/Tooltip";
import Checkbox from "../Utilities/Checkbox";

interface Props {
  node: DataFieldNode;
  update(node: DataFieldNode): void;
}

const setModeOptions = [
  { label: "request", value: "request" },
  { label: "set", value: "set" },
];

const validationModeOptions = [
  { label: "Automatic", value: "automatic" },
  { label: "Manual", value: "manual" },
];

export default function DataFieldNodeEditor({ node, update }: Props) {
  const updateDataField = useCallback(
    (i: number, value?: DataField) => {
      const clone = { ...node, dataFields: [...node.dataFields] };
      if (value) {
        clone.dataFields[i] = value;
      } else {
        clone.dataFields.splice(i, 1);
      }
      update(clone);
    },
    [node, update]
  );

  return (
    <>
      <FormGroup>
        <TextBox
          placeholder="node name"
          onChange={(value) => update({ ...node, name: value })}
          value={node.name || ""}
        />
      </FormGroup>
      <Border />
      {/* <FormGroup>
        <Button
          text="Add data"
          onClick={() => {
            update({
              ...node,
              dataFields: [...node.dataFields, { name: 'name', type: 'string' }]
            })
          }}
        />
      </FormGroup> */}
      {node.dataFields.map((dataField, i) => (
        <div className="" key={i}>
          <FormGroupInline>
            <>
              <TextBox
                width="w-full"
                placeholder="name"
                onChange={(val) =>
                  updateDataField(i, { ...dataField, name: val })
                }
                value={dataField.name}
              />
              <Select
                width="auto"
                options={DataFieldTypeDict}
                onChange={(val: DataFieldType) =>
                  updateDataField(i, {
                    ...dataField,
                    type: val,
                  })
                }
                value={dataField.type}
              />
              {node.dataFields.length > 1 && (
                <Tooltip text="remove">
                  <Clickable onClick={() => updateDataField(i)}>
                    <span className="text-red-500">
                      <X />
                    </span>
                  </Clickable>
                </Tooltip>
              )}
            </>
          </FormGroupInline>
        </div>
      ))}
      <FormGroup>
        <ToggleButtonGroup
          value={node.dataMode}
          options={setModeOptions}
          onChange={(value: DataMode) => update({ ...node, dataMode: value })}
        />
      </FormGroup>
      {node.dataMode === "set" && (
        <FormGroup>
          <TextBox
            placeholder="value"
            onChange={(value) => update({ ...node, val: value })}
            value={node.val || ""}
          />
        </FormGroup>
      )}
      {node.dataMode === "request" && (
        <>
          <FormGroup>
            <Checkbox
              id="required"
              label="Required"
              checked={node.required}
              onToggle={() => update({ ...node, required: !node.required })}
            />
          </FormGroup>
          <FormGroup>
            <Checkbox
              id="validation"
              label="Validate"
              checked={node.validate}
              onToggle={() => update({ ...node, validate: !node.validate })}
            />
          </FormGroup>
          {node.validate && (
            <FormGroup>
              <ToggleButtonGroup
                value={node.validationMode}
                options={validationModeOptions}
                onChange={(value: "automatic" | "manual") =>
                  update({ ...node, validationMode: value })
                }
              />
            </FormGroup>
          )}
        </>
      )}
    </>
  );
}
