import React, { useCallback, useEffect, useMemo } from 'react'
import { StopNode } from '../../models/nodes'
import { StopActionDefs, StopActionType } from '../../models/types'
import Select from '../Utilities/Select'
import useDataSets from '../../hooks/useDataSets'
import FormGroup from '../Utilities/FormGroup'

interface Props {
  node: StopNode
  update(node: StopNode): void
}

export default function StopNodeEditor({ node, update }: Props) {
  const { loading, dataSets } = useDataSets()
  function updateNode(updates: Partial<StopNode>) {
    update({ ...node, ...updates })
  }

  const getDataSet = useCallback(
    (name: string) => {
      const dataSet = dataSets.find((ds) => ds.name === name)
      console.log('dataSet', dataSet)

      return dataSet ? dataSet.data : {}
    },
    [dataSets]
  )

  const dataSet = useMemo(() => {
    switch (node.stopAction) {
      case 'end-automation':
        return null
      case 'route-to-team':
        return getDataSet('Teams')
      case 'route-to-agent':
        return getDataSet('Users')
      default:
        throw new Error('Invalid stop action')
    }
  }, [getDataSet, node.stopAction])

  useEffect(() => {
    if (dataSet && !node.stopValue) {
      updateNode({ stopValue: Object.keys(dataSet)[0] })
    }
  }, [dataSet, node.stopValue, updateNode])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <>
      <FormGroup label="Stop Action">
        <Select
          options={StopActionDefs}
          value={node.stopAction}
          onChange={(a: StopActionType) => updateNode({ stopAction: a })}
        />
      </FormGroup>
      {node.stopAction === 'end-automation' && (
        <FormGroup>
          <div>The automation will end</div>
        </FormGroup>
      )}
      {node.stopAction !== 'end-automation' && dataSet && (
        <FormGroup>
          <Select
            options={dataSet}
            value={node.stopValue || ''}
            onChange={(a: StopActionType) => updateNode({ stopValue: a })}
          />
        </FormGroup>
      )}
    </>
  )
}
