import React from 'react'
import { DataFieldNode, NodeOptions } from '../../../models/nodes'
import Clip from '../../Utilities/Icons/Clip'
import { Tag } from '../../Utilities/Tag'
import RectangleNode from './shared/RectangleNode'

interface Props {
  node: DataFieldNode
  options: NodeOptions
}

export default function CanvasDataFieldNode({ node, options }: Props) {
  return (
    <RectangleNode
      bg={'bg-indigo-50'}
      border={'border-indigo-800'}
      icon={<Clip color="text-indigo-800" />}
      highlightColor={options.highlightColor}
    >
      <span className="flex flex-row flex-wrap justify-evenly items-center">
        {node.dataMode === 'request' && (
          <>
            <span className="pr-1">Request Data: </span>
            <Tag>{node.dataFields[0].name}</Tag>
          </>
        )}
        {node.dataMode === 'set' && (
          <>
            <span className="pr-1">Set Data: </span>{' '}
            <Tag>{node.dataFields[0].name}</Tag>
            <span>=</span>
            <span className="font-bold">{node.val}</span>
          </>
        )}
      </span>
    </RectangleNode>
  )
}
