import React from 'react'

interface Props {
  value: number
  onChange(value: number): void
  placeholder?: string
  width?: string
  max?: number
  min?: number
  step?: number
  label?: string
}

export default function NumberBox({
  value,
  onChange,
  placeholder,
  width,
  max,
  min,
  step,
  label
}: Props) {
  width = width || 'w-full'
  return (
    <>
      {label && (
        <label className="absolute right-7 bottom-1/4 text-sm italic">
          {label}
        </label>
      )}
      <input
        type={'number'}
        max={max}
        min={min}
        step={step}
        placeholder={placeholder}
        className={`inline p-2 ${width} text-sm rounded-lg border shadow-sm`}
        onChange={(e) => onChange(Number(e.currentTarget.value))}
        value={value}
      />
    </>
  )
}
