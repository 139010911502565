import React from 'react'
import LoadingSpinner from '../Utilities/LoadingSpinner'
import useAuth from '../../hooks/useAuth'

export default function Logout() {
  const auth = useAuth()

  React.useLayoutEffect(() => {
    auth.logout()
  }, [auth])

  return <LoadingSpinner />
}
