import { log } from "console";
import { marked } from "marked";
import { useEffect, useMemo, useState } from "react";

export default function useMarked<T>(message: T) {
  const [content, setContent] = useState<string | T | undefined>(undefined);

  const getContent = useMemo(async () => {
    if (typeof message === "string") {
      return await marked.parseInline(message);
    } else {
      return message;
    }
  }, [message]);

  useEffect(() => {
    getContent.then((result) => {
      setContent(result);
    });
  }, [getContent]);

  return { content };
}
