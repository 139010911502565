import { DataSet } from '../models/types'
import AuthService from './AuthService'
import RestService from './RestService'

const url = `${import.meta.env.VITE_API_URL}/datasets`
const api = new RestService({
  rootUrl: url,
  tokenAccessor: () => AuthService.token.token
})
export async function getAll(): Promise<DataSet[]> {
  return await api.get('').then((response) => response.json())
}
