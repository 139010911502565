import React from 'react'
import useAuth from '../../hooks/useAuth'

export default function LoginCallbackError() {
  const auth = useAuth()

  return (
    <div style={{ textAlign: 'center', padding: 20 }}>
      <p>An unexpected error has occurred while attempting to log in.</p>
      <div>
        <button
          onClick={() => {
            auth.retryLogin()
          }}
        >
          Retry
        </button>
      </div>
    </div>
  )
}
