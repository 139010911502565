import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TransformComponent } from 'react-zoom-pan-pinch'
import getHighlightColors from '../../helpers/getHighlightColors'
import { posMatch } from '../../helpers/positionHelpers'
import { NodeBase } from '../../models/nodes'
import {
  DragNodeData,
  SelectionModeData,
  Pos,
  NodeArrowData
} from '../../models/types'
import CanvasArrows from './Arrows/CanvasArrows'
import CanvasGridItem from './CanvasGridItem'
import CanvasNode from './Nodes/shared/CanvasNode'
import { on } from 'events'

interface GridItemData {
  nodeIndex: number | null
}

interface Props {
  scale: number
  nodes: NodeBase[]
  draggingNodeData: DragNodeData | null
  selectionModeData: SelectionModeData | null
  onRemoveNode(i: number): void
  onClickModifyLinks(i: number): void
  onLoaded(): void
  onDragEnter(pos: Pos): void
  onDragLeave(): void
  onSwapNode(i: number): void
  onLeftClickNode(i: number): void
  onDragNodeStart(i: number): void
  onDragNodeStop(): void
  onDropNode(dropPos: Pos, nodeIndex: number): void
  onLeftClickGridItem(pos: Pos): void
}

const gridSizeX = 25
const gridSizeY = 25

export default function CanvasGrid({
  scale,
  nodes,
  draggingNodeData,
  selectionModeData,
  onRemoveNode,
  onClickModifyLinks,
  onLoaded,
  onDragEnter,
  onDragLeave,
  onLeftClickNode,
  onDragNodeStart,
  onDragNodeStop,
  onDropNode,
  onLeftClickGridItem
}: Props) {
  const [nodesLoaded, setNodesLoaded] = useState(false) //have the nodes been rendered
  const [nodeRefs, setNodeRefs] = useState<HTMLDivElement[]>([]) //refs to all nodes
  const [gridRefs, setGridRefs] = useState<HTMLDivElement[]>([]) //refs to all grid items
  const [gridItemData, setGridItemData] = useState<GridItemData[]>(
    new Array(gridSizeX * gridSizeY) //tells us if there's a node at a grid position
  )
  const [hoverIndex, setHoverIndex] = useState<number | null>(null)

  const nodeData = useMemo(() => {
    const isDragging = draggingNodeData && !draggingNodeData.init
    if (isDragging) {
      return null
    }
    const result: Record<string, NodeArrowData> = {}

    nodeRefs.forEach((ref, i) => {
      const node = nodes[i]
      if (!ref || !node) {
        return null
      }

      const rect = ref.getBoundingClientRect()

      result[node.id] = {
        left: ref.offsetLeft + gridRefs[i]?.offsetLeft,
        top: ref.offsetTop + gridRefs[i]?.offsetTop,
        width: rect.width / scale,
        height: rect.height / scale,
        offset: ref.offsetLeft,
        pos: node.pos
      }
    })

    return result
  }, [draggingNodeData, gridRefs, nodeRefs, nodes, scale])

  useEffect(() => {
    if (!nodes || !nodes.length) {
      return
    }
    setGridItemData((orig) => {
      const clone = [...orig]

      nodes.forEach((node, i) => {
        const gridIndex = gridSizeX * (node.pos.y - 1) + (node.pos.x - 1)

        const existingIndex = clone.findIndex(
          (item) => item && item.nodeIndex === i
        )

        if (existingIndex < 0) {
          //new node
          clone[gridIndex] = { nodeIndex: i }
        } else if (existingIndex !== gridIndex) {
          //moved node
          clone[existingIndex] = { nodeIndex: null }
          clone[gridIndex] = { nodeIndex: i }
        }
      })

      return clone
    })
  }, [nodes])

  const renderGridItem = useCallback(
    (data: GridItemData, gridIndex: number) => {
      const pos: Pos = {
        x: (gridIndex % gridSizeX) + 1,
        y: Math.trunc(gridIndex / gridSizeX) + 1
      }

      const isDragging = draggingNodeData && !draggingNodeData.init
      const isDraggedOver =
        isDragging && posMatch(draggingNodeData?.currentPos, pos)
      const selectionType = selectionModeData?.selectionType
      const isAddingNode = selectionType === 'add-node'
      const isHoveredOnAdd = hoverIndex === gridIndex && isAddingNode
      const actionClasses =
        'cursor-pointer border-dashed border-4 rounded border-blue-500 opacity-80'

      let className = ''
      if (isDraggedOver || isHoveredOnAdd) {
        className = actionClasses + ' bg-blue-400'
      } else if (isDragging || isAddingNode) {
        className = actionClasses
      }

      let content = <></>
      let node: NodeBase | null = null
      let highlightColor = undefined
      let selected = false

      if (data && data.nodeIndex !== null) {
        const nodeIndex = data.nodeIndex
        node = nodes[nodeIndex]
        if (!node) {
          //this is a deleted node - update grid data
          setGridItemData((orig) => {
            const clone = [...orig]
            clone[gridIndex] = { nodeIndex: null }
            return clone
          })
        } else {
          highlightColor = getHighlightColors(
            nodes,
            selectionModeData,
            node
          ).nodeHighlight

          selected = selectionType === 'select' && !!highlightColor
          content = (
            <CanvasNode
              onLoad={(el) => {
                setNodeRefs((els) => {
                  const clone = [...els]
                  clone[nodeIndex] = el
                  return clone
                })
              }}
              node={node}
              selected={selected}
              hovering={hoverIndex === gridIndex && selectionModeData === null}
              highlightColor={highlightColor}
              onRemove={() => onRemoveNode(nodeIndex)}
              onAddLink={() => onClickModifyLinks(nodeIndex)}
              onLeftClick={() => onLeftClickNode(nodeIndex)}
              onDragStart={() => onDragNodeStart(nodeIndex)}
              onDragStop={() => onDragNodeStop()}
            />
          )
        }
      }

      return (
        <CanvasGridItem
          key={gridIndex}
          equalityObj={{
            node,
            className,
            highlightColor,
            hoverIndex,
            selectionType
          }}
          className={className}
          onLeftClick={() => onLeftClickGridItem(pos)}
          onDragEnter={() => onDragEnter(pos)}
          onDragLeave={() => onDragLeave()}
          onHoverChange={(hovering) =>
            hovering ? setHoverIndex(gridIndex) : setHoverIndex(null)
          }
          onDrop={() => onDropNode(pos, draggingNodeData?.nodeIndex || 0)}
          onLoad={(el) => {
            if (data?.nodeIndex === null || data?.nodeIndex === undefined) {
              return
            }
            setGridRefs((els) => {
              const clone = [...els]
              clone[data.nodeIndex || 0] = el
              return clone
            })
          }}
          x={pos.x}
          y={pos.y}
        >
          {content}
        </CanvasGridItem>
      )
    },
    [
      draggingNodeData,
      hoverIndex,
      nodes,
      onClickModifyLinks,
      onDragEnter,
      onDragLeave,
      onDragNodeStart,
      onDragNodeStop,
      onDropNode,
      onLeftClickGridItem,
      onLeftClickNode,
      onRemoveNode,
      selectionModeData
    ]
  )

  const gridItems = useMemo(() => {
    return gridItemData.map(renderGridItem)
  }, [gridItemData, renderGridItem])

  useEffect(() => {
    if (nodesLoaded) {
      return
    }

    if (nodeRefs && nodeRefs.length === nodes.length) {
      setNodesLoaded(true)
      onLoaded()
    }
  }, [nodesLoaded, nodeRefs, nodes.length, onLoaded])

  return (
    <>
      <TransformComponent>
        <div
          id="canvas-grid"
          className="grid overflow-auto grid-flow-col auto-cols-max gap-x-20 gap-y-5 p-96"
        >
          {...gridItems}
          <CanvasArrows
            nodes={nodes}
            nodeArrowData={nodeData}
            selectionModeData={selectionModeData}
          />
        </div>
      </TransformComponent>
    </>
  )
}
