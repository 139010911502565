import React, { useCallback, useMemo } from 'react'
import { NodeArrowData, Pos } from '../../../models/types'

interface Props {
  arrowColor: string
  id: string
  startNode: NodeArrowData
  endNode: NodeArrowData
}

export default function CanvasArrow({
  arrowColor,
  id,
  startNode,
  endNode
}: Props) {
  const straightHorz = startNode.pos.x === endNode.pos.x
  const straightVert = startNode.pos.y === endNode.pos.y
  const straight = straightHorz || straightVert

  //anchors are the location on the node we attach to
  const determineAnchor = useCallback(
    (here: NodeArrowData, there: NodeArrowData) => {
      const xDelta = here.pos.x - there.pos.x
      const yDelta = here.pos.y - there.pos.y

      const res = {
        x: here.left, //default to left
        y: here.top //default to top
      }

      if (Math.abs(xDelta) > 0) {
        //horizontal
        if (xDelta < 0) {
          res.x += here.width //right
        }
        res.y += here.height / 2 //half way down
      } else {
        //vertical
        if (yDelta < 0) {
          res.y += here.height //bottom
        }
        res.x += here.width / 2 ////half way right
      }

      return res
    },
    []
  )

  //breaks are the 2 points where the arrow breaks 90 deg
  const determineBreak = useCallback(
    (
      here: {
        anchor: Pos
        node: NodeArrowData
      },
      there: {
        anchor: Pos
        node: NodeArrowData
      }
    ) => {
      if (straight) {
        //this value won't be used
        return {
          x: 0,
          y: 0
        }
      }

      const backwards = there.node.pos.x < here.node.pos.x ? -1 : 1
      const hereX = here.anchor.x + here.node.offset * backwards
      const thereX = there.anchor.x + there.node.offset * backwards * -1
      return {
        x: hereX + (thereX - hereX) / 2,
        y: here.anchor.y
      }
    },
    [straight]
  )

  const startAnchor = useMemo(
    () => determineAnchor(startNode, endNode),
    [determineAnchor, endNode, startNode]
  )

  const endAnchor = useMemo(
    () => determineAnchor(endNode, startNode),
    [determineAnchor, endNode, startNode]
  )

  const startBreak = useMemo(
    () =>
      determineBreak(
        {
          anchor: startAnchor,
          node: startNode
        },
        {
          anchor: endAnchor,
          node: endNode
        }
      ),
    [determineBreak, endAnchor, endNode, startAnchor, startNode]
  )

  const endBreak = useMemo(
    () =>
      determineBreak(
        {
          anchor: endAnchor,
          node: endNode
        },
        {
          anchor: startAnchor,
          node: startNode
        }
      ),
    [determineBreak, endAnchor, endNode, startAnchor, startNode]
  )

  //the final line has an arrow, and the line needs to be adjusted slightly to account for it
  const determineArrowMod = useCallback((start: number, end: number) => {
    const delta = start - end
    if (!delta) {
      return 0
    }
    const mult = delta / Math.abs(delta)
    return mult * 10
  }, [])

  const d1 = useMemo(() => {
    if (straightVert) {
      return `M${startAnchor.x},${startAnchor.y} L${
        endAnchor.x + determineArrowMod(startAnchor.x, endAnchor.x)
      },${startAnchor.y}`
    }
    if (straightHorz) {
      return `M${startAnchor.x},${startAnchor.y} L${startAnchor.x},${
        endAnchor.y + determineArrowMod(startAnchor.y, endAnchor.y)
      }`
    }

    return `M${startAnchor.x},${startAnchor.y} L${startBreak.x},${startBreak.y}`
  }, [
    determineArrowMod,
    endAnchor,
    startAnchor,
    startBreak,
    straightHorz,
    straightVert
  ])

  const d2 = useMemo(() => {
    return `M${startBreak.x},${startBreak.y} L${endBreak.x},${endBreak.y}`
  }, [endBreak.x, endBreak.y, startBreak.x, startBreak.y])

  const d3 = useMemo(() => {
    const horzArrowMod = determineArrowMod(endBreak.x, endAnchor.x)
    const vertArrowMod = determineArrowMod(endBreak.y, endAnchor.y)
    return `M${endBreak.x},${endBreak.y} L${endAnchor.x + horzArrowMod},${
      endAnchor.y + vertArrowMod
    }`
  }, [determineArrowMod, endAnchor.x, endAnchor.y, endBreak.x, endBreak.y])

  const pathProps = {
    stroke: arrowColor,
    strokeWidth: '3px',
    fill: 'none'
  }
  return (
    <>
      {straight && (
        <path
          d={d1}
          style={{
            ...pathProps,
            markerStart: `url(#arrow-start_${id})`,
            markerEnd: `url(#arrow-end_${id})`
          }}
        />
      )}
      {!straight && (
        <>
          <path
            d={d1}
            style={{
              ...pathProps,
              markerStart: `url(#arrow-start_${id})`
            }}
          />
          <path
            d={d2}
            style={{
              ...pathProps,
              markerStart: `url(#arrow-joint_${id})`,
              markerEnd: `url(#arrow-joint_${id})`
            }}
          />
          <path
            d={d3}
            style={{
              ...pathProps,
              markerEnd: `url(#arrow-end_${id})`
            }}
          />
        </>
      )}
    </>
  )
}
