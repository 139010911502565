import React from "react";
import CanvasBotNode from "../components/Canvas/Nodes/BotNode";
import CanvasConditionNode from "../components/Canvas/Nodes/ConditionNode";
import CanvasCustomNode from "../components/Canvas/Nodes/CustomNode";
import CanvasDataFieldNode from "../components/Canvas/Nodes/DataFieldNode";
import CanvasGoToNode from "../components/Canvas/Nodes/GoToNode";
import CanvasNoteNode from "../components/Canvas/Nodes/NoteNode";
import CanvasStartNode from "../components/Canvas/Nodes/StartNode";
import CanvasStopNode from "../components/Canvas/Nodes/StopNode";
import CanvasUserNode from "../components/Canvas/Nodes/UserNode";
import CanvasWaitNode from "../components/Canvas/Nodes/WaitNode";
import BotNodeEditor from "../components/EditorWindow/BotNodeEditor";
import ConditionNodeEditor from "../components/EditorWindow/ConditionNodeEditor";
import CustomNodeEditor from "../components/EditorWindow/CustomNodeEditor";
import DataFieldNodeEditor from "../components/EditorWindow/DataFieldNodeEditor";

import GoToNodeEditor from "../components/EditorWindow/GoToNodeEditor";
import NoteNodeEditor from "../components/EditorWindow/NoteNodeEditor";
import UserNodeEditor from "../components/EditorWindow/UserNodeEditor";
import WaitNodeEditor from "../components/EditorWindow/WaitNodeEditor";
import { RelativeTime } from "../components/Utilities/RelativeTimePicker";
import { generateGuid } from "../utils";
import {
  Pos,
  DataField,
  CustomActionData,
  Condition,
  DataMode,
  WaitMode,
  GoToMode,
  StopActionType,
} from "./types";
import StopNodeEditor from "../components/EditorWindow/StopNodeEditor";

export interface NodeOptions {
  highlightColor?: string;
}

export abstract class NodeBase {
  abstract type: NodeType; //Type always needs to be the first thing serialized
  id: string = generateGuid();
  pos: Pos;
  children: string[] = [];
  name: string | null = null;
  abstract title: string;
  abstract getNodeContent: (options: NodeOptions) => JSX.Element;
  abstract getEditorContent: (
    onUpdate: (node: NodeBase) => void
  ) => JSX.Element;

  constructor(pos?: Pos) {
    this.pos = pos || { x: 0, y: 0 };
  }
}

export class StartNode extends NodeBase {
  type = "StartNode" as NodeType;
  title = "Start";

  getNodeContent = function (options: NodeOptions) {
    return <CanvasStartNode options={options} />;
  };

  getEditorContent = function (
    this: BotNode,
    onUpdate: (node: BotNode) => void
  ) {
    return <></>;
  };
}

export class StopNode extends NodeBase {
  type = "StopNode" as NodeType;
  title = "Stop";
  stopAction: StopActionType = "end-automation";
  stopValue: any = null;

  getNodeContent = function (this: StopNode, options: NodeOptions) {
    return <CanvasStopNode node={this} options={options} />;
  };

  getEditorContent = function (
    this: StopNode,
    onUpdate: (node: StopNode) => void
  ) {
    return <StopNodeEditor node={this} update={onUpdate} />;
  };
}

export class BotNode extends NodeBase {
  type = "BotNode" as NodeType;
  title = "Bot says";
  messages: string[] = ["Hi. How can I help you?"];

  getNodeContent = function (this: BotNode, options: NodeOptions) {
    return <CanvasBotNode node={this} options={options} />;
  };

  getEditorContent = function (
    this: BotNode,
    onUpdate: (node: BotNode) => void
  ) {
    return <BotNodeEditor node={this} update={onUpdate} />;
  };
}

export class UserNode extends NodeBase {
  type = "UserNode" as NodeType;
  title = "User says";
  utterance = "look up account info";
  showAsQuickReply = true;

  getNodeContent = function (this: UserNode, options: NodeOptions) {
    return <CanvasUserNode node={this} options={options} />;
  };

  getEditorContent = function (
    this: UserNode,
    onUpdate: (node: UserNode) => void
  ) {
    return <UserNodeEditor node={this} update={onUpdate} />;
  };
}

export class DataFieldNode extends NodeBase {
  type = "DataFieldNode" as NodeType;
  title = "Gather data";
  dataFields: DataField[] = [{ name: "name", type: "string" }];
  dataMode: DataMode = "request";
  validate = true;
  validationMode: "automatic" | "manual" = "automatic";
  required = true;
  val = "";

  getNodeContent = function (this: DataFieldNode, options: NodeOptions) {
    return <CanvasDataFieldNode node={this} options={options} />;
  };

  getEditorContent = function (
    this: DataFieldNode,
    onUpdate: (node: DataFieldNode) => void
  ) {
    return <DataFieldNodeEditor node={this} update={onUpdate} />;
  };
}

export class GoToNode extends NodeBase {
  type = "GoToNode" as NodeType;
  title = "Go to";
  target = "";
  goToMode: GoToMode = "jump";

  getNodeContent = function (this: GoToNode, options: NodeOptions) {
    return <CanvasGoToNode node={this} options={options} />;
  };

  getEditorContent = function (
    this: GoToNode,
    onUpdate: (node: GoToNode) => void
  ) {
    return <GoToNodeEditor node={this} update={onUpdate} />;
  };
}

export class CustomNode extends NodeBase {
  type = "CustomNode" as NodeType;
  title = "Custom action";
  val = "";
  customActionData: CustomActionData = { method: "POST", url: "", data: [] };

  getNodeContent = function (this: CustomNode, options: NodeOptions) {
    return <CanvasCustomNode node={this} options={options} />;
  };

  getEditorContent = function (
    this: CustomNode,
    onUpdate: (node: CustomNode) => void
  ) {
    return <CustomNodeEditor node={this} update={onUpdate} />;
  };
}

export class WaitNode extends NodeBase {
  type = "WaitNode" as NodeType;
  title = "Wait";
  waitType: WaitMode = "wait_for";
  waitForValue: RelativeTime = { value: 3, units: "hours" };
  waitUntilValue: Date = new Date();
  getNodeContent = function (this: WaitNode, options: NodeOptions) {
    return <CanvasWaitNode node={this} options={options} />;
  };

  getEditorContent = function (
    this: WaitNode,
    onUpdate: (node: WaitNode) => void
  ) {
    return <WaitNodeEditor node={this} update={onUpdate} />;
  };
}

export class NoteNode extends NodeBase {
  type = "NoteNode" as NodeType;
  title = "Note";
  val = "";
  getNodeContent = function (this: NoteNode, options: NodeOptions) {
    return <CanvasNoteNode node={this} options={options} />;
  };

  getEditorContent = function (
    this: NoteNode,
    onUpdate: (node: NoteNode) => void
  ) {
    return <NoteNodeEditor node={this} update={onUpdate} />;
  };
}

export class ConditionNode extends NodeBase {
  type = "ConditionNode" as NodeType;
  title = "Condition";
  condition: Condition = { data: "", comparator: "=", value: "0", priority: 1 };
  getNodeContent = function (this: ConditionNode, options: NodeOptions) {
    return <CanvasConditionNode node={this} options={options} />;
  };

  getEditorContent = function (
    this: ConditionNode,
    onUpdate: (node: ConditionNode) => void
  ) {
    return <ConditionNodeEditor node={this} update={onUpdate} />;
  };
}

export const NodeTypeDict = {
  StartNode,
  StopNode,
  BotNode,
  UserNode,
  DataFieldNode,
  GoToNode,
  CustomNode,
  WaitNode,
  NoteNode,
  ConditionNode,
};

export type NodeType = keyof typeof NodeTypeDict;
