import React, { useEffect, useMemo, useState } from "react";
import getDataFieldMatches from "../../../helpers/getDataFieldMatches";
import { BotNode, NodeOptions } from "../../../models/nodes";
import { Tag } from "../../Utilities/Tag";
import RectangleNode from "./shared/RectangleNode";
import Chip from "../../Utilities/Icons/Chip";
import { marked } from "marked";
import LoadingSpinner from "../../Utilities/LoadingSpinner";

interface Props {
  node: BotNode;
  options: NodeOptions;
}

export default function CanvasBotNode({ node, options }: Props) {
  const [content, setContent] = useState<
    (React.JSX.Element | string)[][] | null
  >(null);

  const getContent = useMemo(async () => {
    const result = [];
    for (const message of node.messages) {
      const htmlResult = [];
      const matches = getDataFieldMatches(message);

      let key = 0;
      let currentIndex = 0;

      for (const match of matches) {
        const toReplace = match[0];
        const dataFieldName = match[1];
        const index = match.index || 0;
        htmlResult.push(
          await marked.parseInline(message.substring(currentIndex, index))
        );
        htmlResult.push(<Tag key={key++}>{dataFieldName}</Tag>);
        currentIndex = index + toReplace.length;
      }

      //the final part of the message
      htmlResult.push(
        await marked.parseInline(
          message.substring(currentIndex, message.length)
        )
      );
      result.push(htmlResult);
    }
    // array join results in a flat string
    return result;
  }, [node.messages]);

  useEffect(() => {
    getContent.then((result) => {
      setContent(result);
    });
  }, [getContent]);

  if (content === null) {
    return <LoadingSpinner />;
  }

  return (
    <RectangleNode
      bg={"bg-pink-50"}
      border={"border-pink-800"}
      icon={<Chip color="text-pink-800" />}
      highlightColor={options.highlightColor}
    >
      <div className="flex flex-row flex-wrap justify-evenly items-center text-sm leading-6">
        {!content.length && "(Empty)"}
        {content.length &&
          content.map((message, i) => (
            <div
              className={`items-center overflow-hidden overflow-ellipsis mx-2 py-4 ${
                i !== node.messages.length - 1 ? "border-b" : ""
              }`}
              key={i}
            >
              {message.map((part, j) => (
                <>
                  {typeof part === "string" && (
                    <span
                      key={j}
                      dangerouslySetInnerHTML={{ __html: part }}
                    ></span>
                  )}
                  {typeof part !== "string" && <span key={j}>{part}</span>}
                </>
              ))}
            </div>
          ))}
      </div>
    </RectangleNode>
  );
}
