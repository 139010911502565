import { PostAutomation, DeleteAutomation } from '../models/api'
import { Automation } from '../models/types'
import AuthService from './AuthService'
import RestService from './RestService'

const url = `${import.meta.env.VITE_API_URL}/automationdesign`
const api = new RestService({
  rootUrl: url,
  tokenAccessor: () => AuthService.token.token
})
export async function getAll(): Promise<Automation[]> {
  return await api.get('').then((response) => response.json())
}

export async function add(payload: PostAutomation): Promise<Automation> {
  return await api.post('', payload).then((response) => response.json())
}

export async function update(payload: Automation) {
  await api.put('', payload)
}

export async function remove(payload: DeleteAutomation) {
  await api.delete('', payload)
}
