import React from 'react'
import { CustomNode, NodeOptions } from '../../../models/nodes'
import RectangleNode from './shared/RectangleNode'

interface Props {
  node: CustomNode
  options: NodeOptions
}

export default function CanvasCustomNode({ node, options }: Props) {
  return (
    <RectangleNode
      bg="bg-gray-50"
      border={'border-gray-800'}
      highlightColor={options.highlightColor}
    >
      {/* <Bolt /> */}
      <div className={`flex items-center gap-4 px-2 py-4`}>
        {node.customActionData?.method}
        <div className="font-mono text-xs break-all">
          {node.customActionData?.url}
        </div>
      </div>
    </RectangleNode>
  )
}
