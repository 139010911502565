import { useCallback, useMemo, useState } from 'react'
import { Automation } from '../models/types'

export default function useSelectedAutomation(automations: Automation[]) {
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const selectedAutomation = useMemo(
    () =>
      automations === null || selectedIndex === null
        ? null
        : automations[selectedIndex],
    [automations, selectedIndex]
  )

  const changeAutomation = useCallback(
    (index?: number) => {
      console.log('changing automation', index)

      if (index !== undefined) {
        console.log('changing selected index to', index)
        setSelectedIndex(index)
        return
      }

      if (selectedIndex == null) {
        console.log('defaulting selected index to 0')
        setSelectedIndex(0)
        return
      }
    },
    [selectedIndex]
  )

  return {
    selectedIndex,
    selectedAutomation,
    changeAutomation
  }
}
