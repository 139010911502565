import 'tailwindcss/tailwind.css'
import './css/main.css'
import './css/loading_spinner.css'
import 'tailwindcss/colors'
import { BrowserRouter } from 'react-router-dom'
import { AppRoutes } from './routes/Routes'
import { createRoot } from 'react-dom/client'
import React from 'react'

const element = document.getElementById('root')
if (element) {
  const root = createRoot(element)
  root.render(<BrowserRouter>{<AppRoutes />}</BrowserRouter>)
}
